import React from "react";
import { Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import {
  selectInvitesQuantityValue,
  selectPricePerInviteValue,
} from "../weddingSlice";

export const WeddingOrderTotals = () => {
  const invitePrice = useSelector(selectPricePerInviteValue);
  const quantity = useSelector(selectInvitesQuantityValue);
  return (
    <Grid container xs={12}>
      <Grid item container xs={11} justifyContent={"space-between"}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Total
        </Typography>
        <Typography sx={{ padding: "5px", fontWeight: "bold" }}>
          {(
            (invitePrice.folio + invitePrice.cardboard + invitePrice.softouch) *
            quantity
          ).toFixed(1)}
          RON
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Divider />
      </Grid>
    </Grid>
  );
};
