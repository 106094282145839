import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTextValue,
  selectInviteValues,
  selectTextValues,
} from "../weddingSlice";

export const WeddingLocationDetails = () => {
  const dispatch = useDispatch();
  const invite = useSelector(selectInviteValues);
  const texts = useSelector(selectTextValues);
  const findTextValue = (value) => {
    return invite.texts.filter((text) => text.id === value).length === 1;
  };
  return (
    <Grid
      item
      container
      xs={12}
      sm={11}
      paddingX={{ xs: "10px", sm: "0px" }}
      alignItems="center"
    >
      <Grid xs={12} sm={4}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Detalii locație eveniment
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          Completați detaliile evenimentului așa cum doriți să apară pe
          invitație
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={8} justifyContent="space-between">
        {findTextValue("locatieNunta") && (
          <Grid xs={12} sm={7.8} item sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.locatieNunta}
              label="Locatia evenimentului"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "locatieNunta",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. Sala de evenimente "
              fullWidth
            />
          </Grid>
        )}
        {findTextValue("oraNunta") && (
          <Grid xs={12} sm={3.8} item sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.oraNunta}
              label="Ora evenimentului"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "oraNunta",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. 20:00 "
              fullWidth
            />
          </Grid>
        )}
        {/* <Grid
          xs={12}
          sx={{ paddingBottom: "15px" }}
          justifyContent={"space-between"}
        >
          {/* Aici ramane de pus date pickerul */}
        {/* <TextField
            label="Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Select"
            sx={{ width: "167px", marginRight: "15px" }}
          />
          <TextField
            label="Title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Select"
            sx={{ width: "167px" }}
          />
        </Grid> */}{" "}
        {/* <Grid xs={12}>
          <TextField
            label="Invite description"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Enter the adress"
            multiline
            rows={3}
            sx={{ width: "350px" }}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
