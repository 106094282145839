import Invite1 from "./Invitatie1/Invitatie1.jpg";
import Invite1_Gold from "./Invitatie1/Invitatie1_Gold.JPG";
import Invite1_Silver from "./Invitatie1/Invitatie1_Silver.JPG";
import Invite1_Cupru from "./Invitatie1/Invitatie1_Cupru.JPG";
import Invite1_no_text from "./Invitatie1/Invitatie1_FARA_TEXT.JPG";

import Invite4 from "./Invitatie4/Invitatie4.JPG";
import Invite4_Gold from "./Invitatie4/Invitatie4_Gold.JPG";
import Invite4_Silver from "./Invitatie4/Invitatie4_Silver.JPG";
import Invite4_Cupru from "./Invitatie4/Invitatie4_Cupru.JPG";
import Invite4_Cupru_no_text from "./Invitatie4/Invitatie4_Cupru_FARA_TEXT.JPG";
import Invite4_Gold_no_text from "./Invitatie4/Invitatie4_Gold_FARA_TEXT.JPG";
import Invite4_Silver_no_text from "./Invitatie4/Invitatie4_Silver_FARA_TEXT.JPG";
import Invite4_no_text from "./Invitatie4/Invitatie4_FARA_TEXT.JPG";

import Invite5 from "./Invitatie5/Invitatie5.JPG";
import Invite5_Gold from "./Invitatie5/Invitatie5_Gold.JPG";
import Invite5_Silver from "./Invitatie5/Invitatie5_Silver.JPG";
import Invite5_Cupru from "./Invitatie5/Invitatie5_Cupru.JPG";
import Invite5_Cupru_no_text from "./Invitatie5/Invitatie5_Cupru_FARA_TEXT.JPG";
import Invite5_Gold_no_text from "./Invitatie5/Invitatie5_Gold_FARA_TEXT.JPG";
import Invite5_Silver_no_text from "./Invitatie5/Invitatie5_Silver_FARA_TEXT.JPG";
import Invite5_no_text from "./Invitatie5/Invitatie5_FARA_TEXT.JPG";

import Invite6 from "./Invitatie6/Invitatie6.JPG";
import Invite6_Gold from "./Invitatie6/Invitatie6_Gold.JPG";
import Invite6_Silver from "./Invitatie6/Invitatie6_Silver.JPG";
import Invite6_Cupru from "./Invitatie6/Invitatie6_Cupru.JPG";
import Invite6_no_text from "./Invitatie6/Invitatie6_FARA_TEXT.JPG";

import Invite7 from "./Invitatie7/Invitatie7.JPG";
import Invite7_Gold from "./Invitatie7/Invitatie7_Gold.JPG";
import Invite7_Silver from "./Invitatie7/Invitatie7_Silver.JPG";
import Invite7_Cupru from "./Invitatie7/Invitatie7_Cupru.JPG";
import Invite7_Cupru_no_text from "./Invitatie7/Invitatie7_Cupru_FARA_TEXT.JPG";
import Invite7_Gold_no_text from "./Invitatie7/Invitatie7_Gold_FARA_TEXT.JPG";
import Invite7_Silver_no_text from "./Invitatie7/Invitatie7_Silver_FARA_TEXT.JPG";
import Invite7_no_text from "./Invitatie7/Invitatie7_FARA_TEXT.JPG";

export const invites = [
  {
    previewImage: {
      invitatieGold: Invite1_Gold,
      invitatieSilver: Invite1_Silver,
      invitatieBronze: Invite1_Cupru,
      invitatieSimpla: Invite1,
    },
    images: {
      invitatieGold: Invite1_no_text,
      invitatieSilver: Invite1_no_text,
      invitatieBronze: Invite1_no_text,
      invitatieSimpla: Invite1_no_text,
    },
    titlu: "Venus",
    isSoftouch: false,
    texts: [
      {
        id: "numeMiri",
        text: "Ana",
        left: "20%",
        top: "23%",
        fontSize: "calc(1.5vw + 1.5vh)",
        fontStyle: "normal",
        fontFamily: "Ephesis",
        center: true,
        isFolio: true,
      },
      {
        id: "initialeMiri",
        text: "Ana",
        left: "30%",
        top: "7.1%",
        fontSize: "calc(2vw + 2vh)",
        fontStyle: "bold",
        fontFamily: "Xanh Mono",
        upperCaseOnly: true,
        center: true,
        letterSpacing: "calc(1vw + 1vw)",
      },
      {
        id: "dataNuntii",
        text: "Ana",
        left: "20%",
        top: "46%",
        fontSize: "calc(1.33vw + 1.33vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        isFolio: true,
      },
      {
        id: "locatieNunta",
        text: "Ana",
        left: "20%",
        top: "74%",
        fontSize: "calc(0.43vw + 0.43vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 600,
        upperCaseOnly: true,
        isFolio: true,
      },
      {
        id: "confirmarePrezenta",
        text: "Ana",
        left: "54.8%",
        top: "87.1%",
        fontSize: "calc(0.42vw + 0.42vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        upperCaseOnly: true,
        fontWeight: 600,
        isFolio: true,
      },
      {
        id: "persoanaContact",
        text: "Ana",
        top: "90.5%",
        fontSize: "calc(0.43vw + 0.43vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        upperCaseOnly: true,
        center: true,
        fontWeight: 600,
        isFolio: true,
      },
    ],
  },

  //invitatie 2
  {
    previewImage: {
      invitatieGold: Invite4_Gold,
      invitatieSilver: Invite4_Silver,
      invitatieBronze: Invite4_Cupru,
      invitatieSimpla: Invite4,
    },
    images: {
      invitatieGold: Invite4_Gold_no_text,
      invitatieSilver: Invite4_Silver_no_text,
      invitatieBronze: Invite4_Cupru_no_text,
      invitatieSimpla: Invite4_no_text,
    },
    titlu: "Calypso",
    isSoftouch: false,

    texts: [
      {
        id: "numeMiri",
        text: "Ana",
        left: "20%",
        top: "34%",
        fontSize: "calc(1.17vw + 1.17vh)",
        fontStyle: "bold",
        fontFamily: "Libre Bodoni",
        center: true,
        isFolio: true,
      },
      {
        id: "initialeMiri",
        text: "Ana",
        left: "30%",
        top: "4%",
        fontSize: "calc(4.9vh + 4.9vw)",
        fontStyle: "bold",
        fontFamily: "Xanh Mono",
        upperCaseOnly: true,
        center: true,
        letterSpacing: "calc(1vw + 1vw)",
        isFolio: true,
      },
      {
        id: "dataNuntii",
        text: "Ana",
        left: "20%",
        top: "44%",
        fontSize: "calc(1vw + 1vw)",
        fontFamily: "Libre Bodoni",
        center: true,
        isFolio: true,
      },
      {
        id: "locatieNunta",
        text: "Ana",
        top: "68%",
        fontSize: "calc(0.33vw + 0.33vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
      {
        id: "oraNunta",
        text: "Ana",
        left: "60%",
        top: "63.2%",
        fontSize: "calc(0.4vw + 0.4vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
      {
        id: "locatieCununie",
        text: "Ana",
        top: "58%",
        fontSize: "calc(0.33vw + 0.33vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
      {
        id: "oraCununie",
        text: "Ana",
        left: "67.8%",
        top: "53.4%",
        fontSize: "calc(0.4vw + 0.4vh)",
        fontStyle: "bold",
        fontFamily: "Lora",
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
      {
        id: "confirmarePrezenta",
        text: "Ana",
        left: "53%",
        top: "83.9%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        upperCaseOnly: true,
        fontWeight: 900,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
      {
        id: "persoanaContact",
        text: "Ana",
        top: "88%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        letterSpacing: "calc(0.07vw + 0.07vh)",
        isFolio: true,
      },
    ],
  },
  //invitatie 3
  {
    previewImage: {
      invitatieGold: Invite5_Gold,
      invitatieSilver: Invite5_Silver,
      invitatieBronze: Invite5_Cupru,
      invitatieSimpla: Invite5,
    },
    images: {
      invitatieGold: Invite5_Gold_no_text,
      invitatieSilver: Invite5_Silver_no_text,
      invitatieBronze: Invite5_Cupru_no_text,
      invitatieSimpla: Invite5_no_text,
    },
    titlu: "Elysia",
    isSoftouch: false,

    texts: [
      {
        id: "numeMiri",
        text: "Ana",
        top: "24%",
        fontSize: "calc(0.83vw + 0.83vh)",
        fontStyle: "bold",
        fontFamily: "Ephesis",
        center: true,
        isFolio: true,
      },
      {
        id: "familieMiri",
        text: "Ana",
        top: "34%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontStyle: "bold",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "perechiNasi",
        text: "Ana",
        top: "64%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "dataNuntii",
        text: "Ana",
        top: "41%",
        fontSize: "calc(0.93vw + 0.93vh)",
        fontStyle: "bold",
        fontFamily: "Ephesis",
        center: true,
        isFolio: true,
      },
      {
        id: "locatieNunta",
        text: "Ana",
        top: "56.8%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "oraNunta",
        text: "Ana",
        left: "71.5%",
        top: "53.4%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontFamily: "Roboto",
      },
      {
        id: "locatieCununie",
        text: "Ana",
        top: "50.3%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontStyle: "bold",
        center: true,
        fontFamily: "Roboto",
      },
      {
        id: "oraCununie",
        text: "Ana",
        left: "67.2%",
        top: "47.5%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontFamily: "Roboto",
      },
      {
        id: "confirmarePrezenta",
        text: "Ana",
        left: "52%",
        top: "74.89%",
        fontSize: "calc(0.4vh + 0.4vw)",
        upperCaseOnly: true,
        fontWeight: "bold",
        fontFamily: "Roboto",
      },
      {
        id: "persoanaContact",
        text: "Ana",
        top: "81%",
        fontSize: "calc(0.4vh + 0.4vw)",
        center: true,
        fontFamily: "Roboto",
      },
    ],
  },
  //Invite 4
  {
    previewImage: {
      invitatieGold: Invite6_Gold,
      invitatieSilver: Invite6_Silver,
      invitatieBronze: Invite6_Cupru,
      invitatieSimpla: Invite6,
    },
    images: {
      invitatieGold: Invite6_no_text,
      invitatieSilver: Invite6_no_text,
      invitatieBronze: Invite6_no_text,
      invitatieSimpla: Invite6_no_text,
    },
    titlu: "Afrodita",
    isSoftouch: false,

    texts: [
      {
        id: "numeMiri",
        text: "Ana",
        top: "20.3%",
        fontSize: "calc(0.83vh + 0.83vw)",
        fontStyle: "bold",
        fontFamily: "Ephesis",
        center: true,
        isFolio: true,
      },
      {
        id: "familieMiri",
        text: "Ana",
        top: "30.3%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "perechiNasi",
        text: "Ana",
        top: "61.5%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "dataNuntii",
        text: "Ana",
        top: "37.5%",
        fontSize: "25px",
        fontStyle: "bold",
        fontFamily: "Ephesis",
        center: true,
        isFolio: true,
      },
      {
        id: "locatieNunta",
        text: "Ana",
        top: "52.5%",
        fontSize: "calc(0.45vh + 0.45vw)",
        fontFamily: "Roboto",
        center: true,
      },
      {
        id: "oraNunta",
        text: "Ana",
        left: "71.2%",
        top: "49.5%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontFamily: "Roboto",
      },
      {
        id: "locatieCununie",
        text: "Ana",
        top: "46.5%",
        fontSize: "calc(0.4vh + 0.4vw)",
        center: true,
        fontFamily: "Roboto",
      },
      {
        id: "oraCununie",
        text: "Ana",
        left: "67.4%",
        top: "43.6%",
        fontSize: "calc(0.4vh + 0.4vw)",
        fontFamily: "Roboto",
      },
      {
        id: "confirmarePrezenta",
        text: "Ana",
        left: "52.3%",
        top: "71%",
        fontSize: "calc(0.4vh + 0.4vw)",
        upperCaseOnly: true,
        fontWeight: "bold",
        fontFamily: "Roboto",
      },
      {
        id: "persoanaContact",
        text: "Ana",
        top: "77%",
        fontSize: "calc(0.4vh + 0.4vw)",
        center: true,
        fontFamily: "Roboto",
        fontWeight: "bold",
      },
    ],
  },
  //invitatie 5
  {
    previewImage: {
      invitatieGold: Invite7_Gold,
      invitatieSilver: Invite7_Silver,
      invitatieBronze: Invite7_Cupru,
      invitatieSimpla: Invite7,
    },
    images: {
      invitatieGold: Invite7_Gold_no_text,
      invitatieSilver: Invite7_Silver_no_text,
      invitatieBronze: Invite7_Cupru_no_text,
      invitatieSimpla: Invite7_no_text,
    },
    titlu: "Selene",
    isSoftouch: true,
    texts: [
      {
        id: "numeMiri",
        text: "Ana",
        top: "40%",
        fontSize: "calc(0.83vh + 0.83vw)",
        fontStyle: "bold",
        fontFamily: "Libre Bodoni",
        center: true,
        isFolio: true,
      },
      {
        id: "initialeMiri",
        text: "Ana",
        top: "16%",
        fontSize: "calc(4vh + 4vw)",
        fontStyle: "bold",
        fontFamily: "Xanh Mono",
        upperCaseOnly: true,
        center: true,
        letterSpacing: "30px",
        isFolio: true,
      },
      {
        id: "dataNuntii",
        text: "Ana",
        top: "47%",
        fontSize: "calc(0.8vh + 0.8vw)",
        fontFamily: "Libre Bodoni",
        center: true,
        isFolio: true,
      },
      {
        id: "locatieNunta",
        text: "Ana",
        top: "66%",
        fontSize: "calc(0.35vh + 0.35vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "2px",
        isFolio: true,
      },
      {
        id: "oraNunta",
        text: "Ana",
        left: "59.7%",
        top: "63.9%",
        fontSize: "calc(0.35vh + 0.35vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "2px",
      },
      {
        id: "locatieCununie",
        text: "Ana",
        top: "58%",
        fontSize: "calc(0.35vh + 0.35vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "2px",
        isFolio: true,
      },
      {
        id: "oraCununie",
        text: "Ana",
        left: "65.5%",
        top: "56.1%",
        fontSize: "calc(0.35vh + 0.35vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        fontWeight: 900,
        upperCaseOnly: true,
        letterSpacing: "2px",
      },

      {
        id: "persoanaContact",
        text: "Ana",
        top: "72%",
        fontSize: "calc(0.35vh + 0.35vw)",
        fontStyle: "bold",
        fontFamily: "Lora",
        center: true,
        fontWeight: 900,
        letterSpacing: "2px",
      },
    ],
  },
];
