import React from "react";
import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import {
  changeQuantityValue,
  selectInvitesQuantityValue,
} from "../weddingSlice";
import { useDispatch, useSelector } from "react-redux";

export const WeddingQuantity = () => {
  const dispatch = useDispatch();
  const quantity = useSelector(selectInvitesQuantityValue);
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Grid
        item
        xs={12}
        sm={3}
        marginBottom="10px"
        textAlign={{ xs: "center", md: "unset" }}
      >
        <Typography sx={{ fontWeight: "bold" }}>Cantitate</Typography>
        <Typography>Alege cantitatea</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={3}
        justifyContent={{ xs: "center", md: "none" }}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-number"
            label="Cantitate"
            type="number"
            value={quantity}
            onChange={(event) =>
              dispatch(changeQuantityValue(event.target.value))
            }
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="min. 100"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
