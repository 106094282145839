import React from "react";
import { Grid, Typography } from "@mui/material";
import { WeddingOrderTotals } from "./WeddingSummary/WeddingOrderTotal";
import { WeddingChosenLook } from "./WeddingSummary/WeddingChosenLook";
import { WeddingDetailsSummary } from "./WeddingSummary/WeddingSummaryDetalis";

export const WeddingSummaryStep = () => {
  return (
    <Grid
      item
      container
      justifyContent={{ xs: "center", md: "flex-start" }}
      alignContent="flex-start"
      marginY="5vh"
      xs={12}
    >
      <WeddingOrderTotals />
      <WeddingChosenLook />
      <Grid container xs={12}>
        <Grid item container xs={11} justifyContent={"space-between"}>
          <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
            La pretul total se mai adauga 15 RON pentru transport daca livrarea
            nu este in Cluj - Napoca
          </Typography>
        </Grid>
      </Grid>
      {/* <WeddingDetailsSummary /> */}
    </Grid>
  );
};
