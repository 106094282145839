import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTextValue,
  selectInviteValues,
  selectTextValues,
} from "../weddingSlice";

export const WeddingChurchDetails = () => {
  const dispatch = useDispatch();
  const invite = useSelector(selectInviteValues);
  const texts = useSelector(selectTextValues);
  const findTextValue = (value) => {
    return invite.texts.filter((text) => text.id === value).length === 1;
  };
  return (
    <Grid
      item
      container
      xs={12}
      sm={11}
      paddingX={{ xs: "10px", sm: "0px" }}
      sx={{ paddingBottom: "15px" }}
      alignItems="center"
    >
      <Grid item container xs={12} sm={4}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Detalii cununie religioasa
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          (Optional) Puteți adăuga detalii supliementare
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        justifyContent="space-between"
        alignContent="center"
      >
        {findTextValue("locatieCununie") && (
          <Grid item xs={12} sm={7.8} sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.locatieCununie}
              label="Locatia cununie"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "locatieCununie",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. Sala de evenimente "
              fullWidth
            />
          </Grid>
        )}
        {findTextValue("oraCununie") && (
          <Grid xs={12} sm={3.8} item sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.oraCununie}
              label="Ora cununie"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "oraCununie",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. 20:00 "
              fullWidth
            />
          </Grid>
        )}
        {/* <Grid xs={12}>
          <TextField
            label="Invite description"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Enter the adress"
            multiline
            rows={3}
            sx={{ width: "350px" }}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
