import React from "react";
import { Grid, Button, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { InfoBox } from "./components/InfoBox";
import WeddingIcon from "../../assets/plic.svg";
import StickerIcon from "../../assets/sticker_card_image.png";
import Arrow from "../../assets/Arrow 1.png";
import PuzzleIcon from "../../assets/puzzle.png";

import { Helmet } from "react-helmet";
import { ActionCard } from "./components/ActionCard";
import Card1Icon from "../../assets/Card1.png";
import Card2Icon from "../../assets/Card2.png";
import Card3Icon from "../../assets/Card3.png";
import PozaPapagal from "../../assets/PozaPapagal.jpg";
import PozaChat from "../../assets/PozaChat.png";

import { StepComponent } from "./components/StepComponent";
import { colors } from "../..";

export const HomePageNew = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - 110px)` }}
      justifyContent="center"
    >
      <Helmet>
        <title>PrintWorks</title>
        <meta
          name="description"
          content="Personalizează-ți stickerele și invitațiile de nuntă cu configuratorul nostru intuitiv! Alege designul perfect, adaugă text, culori și elemente grafice pentru a crea invitații unice și elegante. Transformă-ți viziunea în realitate cu doar câteva clicuri"
        />
        <meta
          name="title"
          content="Printworks: Configurator Stickere și Invitații de Nuntă! Creează Designul Perfect!"
        />
      </Helmet>
      <Grid
        item
        container
        xs={10}
        md={12}
        minHeight={`calc(100vh - 50px)`}
        alignContent="center"
        justifyContent="center"
        spacing={6}
        alignItems="center"
      >
        <Grid
          item
          container
          xs={12}
          md={7}
          justifyContent="center"
          alignContent="center"
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
            marginBottom="20px"
          >
            <Typography
              fontFamily="Cabinet Grotesk"
              fontWeight="bold"
              fontSize={{ xs: "48px", sm: "64px", md: "72px" }}
              lineHeight="85px"
            >
              {t("let.us")}
            </Typography>

            <img
              src={Arrow}
              height="30px"
              style={{ marginLeft: "10px" }}
              alt="arrow"
            />
            <br />
            <Typography
              fontFamily="Cabinet Grotesk"
              fontWeight="bold"
              fontSize={{ xs: "48px", sm: "64px", md: "72px" }}
              lineHeight="85px"
            >
              {t("take.care")}
            </Typography>
            <br />
            <Typography
              fontFamily="Cabinet Grotesk"
              fontWeight="bold"
              fontSize={{ xs: "48px", sm: "64px", md: "72px" }}
              lineHeight="85px"
            >
              {t("home.your")}
              <span style={{ color: "#FEBD11", marginLeft: "10px" }}>
                {t("printables")}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            justifyContent="flex-start"
            alignContent="center"
            alignItems="center"
          >
            <Typography fontSize="18px">{t("homepage.subtitle")}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={5} justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            md={10}
            spacing={6}
            alignContent="center"
            justifyContent="center"
          >
            <ActionCard
              icon={StickerIcon}
              firstText={t("create.your.male")}
              textColor="#FEBD11"
              iconHeight="150vh"
              hoverColor="#FFFDEA"
              coloredText={t("home.awesome")}
              toUrl="/sticker"
              finalText={t("custom.stickers")}
            />
            <ActionCard
              icon={WeddingIcon}
              iconHeight="90vh"
              firstText={t("create.your.female")}
              toUrl="/wedding"
              textColor="#7079EC"
              hoverColor="#EFF3FE"
              coloredText={t("home.memorable")}
              finalText={t("wedding.invitations")}
            />
            <ActionCard
              icon={PuzzleIcon}
              firstText={t("create.your.male")}
              textColor="#EC7070"
              iconHeight="90vh"
              hoverColor="#f7c5c5"
              coloredText={t("home.wonderful")}
              toUrl="/puzzle"
              finalText={t("custom.puzzle")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center" marginTop="30px">
        <Grid item container xs={10}>
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            md={4}
            sx={{ marginBottom: { xs: "10px", md: "0" } }}
          >
            <InfoBox
              title={t("shipping.to.your.location")}
              icon={Card1Icon}
              description={t("description.shipping.to.your.location")}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            justifyContent="center"
            sx={{ marginBottom: { xs: "10px", md: "0" } }}
          >
            <InfoBox
              title={t("design.yourself")}
              icon={Card2Icon}
              description={t("description.design.yourself")}
            />
          </Grid>
          <Grid item container xs={12} md={4} justifyContent="center">
            <InfoBox
              title={t("multiple.materials.to.choose.from")}
              icon={Card3Icon}
              description={t("description.multiple.materials.to.choose.from")}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          spacing={6}
          alignItems="center"
          alignContent="center"
          marginTop="50px"
          justifyContent="center"
        >
          <Grid item xs={10} display={{ xs: "flex", md: "none" }}>
            <Typography fontSize="40px" fontWeight="bold">
              {t("home.our.process")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={5}
            justifyContent="center"
            aligncontent={{ xs: "normal", md: "center" }}
            height="55vh"
          >
            <img
              src={PozaPapagal}
              alt="sticker papagal"
              style={{
                borderRadius: "20px",
                maxWidth: "90%",
                maxHeight: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={7}
            alignContent={{ xs: "normal", md: "center" }}
            justifyContent="space-evenly"
          >
            <Grid
              item
              xs={12}
              marginBottom="40px"
              display={{ xs: "none", md: "flex" }}
            >
              <Typography fontSize="40px" fontWeight="bold">
                {t("home.our.process")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <StepComponent
                step="O1"
                stepColor="#FEC93C"
                title="Step 1"
                description={t("step1.homepage")}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <StepComponent
                step="O2"
                stepColor="#EC70A4"
                title="Step 2"
                description={t("step2.homepage")}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <StepComponent
                step="O3"
                stepColor="#EC7070"
                title="Step 3"
                description={t("step3.homepage")}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <StepComponent
                step="O4"
                stepColor="#7079EC"
                title="Step 4"
                description={t("step4.homepage")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} marginY="50px">
        <Grid
          item
          container
          xs={12}
          md={8}
          spacing={6}
          alignContent="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            xs={11}
            alignItems="center"
            justifyContent="center"
            marginY="50px"
            display={{ xs: "flex", md: "none" }}
          >
            <img
              src={PozaChat}
              style={{ maxHeight: "168px" }}
              alt="clienti multumiti"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography
              fontFamily="Cabinet Grotesk"
              fontSize="40px"
              fontWeight="bold"
              marginRight="10px"
            >
              {t("home.make.sure")}{" "}
              <span style={{ color: "#FEBD11" }}>{t("printables")}!</span>
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{t("home.makesure.subtitle")}</Typography>
          </Grid>
          <Grid item container xs={9} justifyContent="space-evenly" spacing={6}>
            <Grid item cotnainer xs={11} md={5}>
              <Button
                sx={{
                  backgroundColor: colors.customYellow,
                  color: colors.text,
                  padding: "8px  24px 8px 24px",
                  borderRadius: "20px",
                  boxShadow: " 2px 2px 2px black;",
                }}
                fullWidth
              >
                {t("header.sticker")}
              </Button>
            </Grid>
            <Grid
              item
              cotnainer
              xs={11}
              md={2}
              textAlign="center"
              alignItems="center"
            >
              <Typography>OR</Typography>
            </Grid>
            <Grid item cotnainer xs={11} md={5}>
              <Button
                sx={{
                  backgroundColor: colors.customYellow,
                  color: colors.text,
                  padding: "8px  24px 8px 24px",
                  borderRadius: "20px",
                  boxShadow: " 2px 2px 2px black",
                }}
                fullWidth
              >
                {t("create.wedding.button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={4}
          alignItems="center"
          display={{ xs: "none", md: "flex" }}
        >
          <img
            src={PozaChat}
            style={{ maxHeight: "168px" }}
            alt="clienti multumiti"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
