import React from "react";
import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeFileToSendValue, selectInviteValues } from "./weddingSlice";
import { useNavigate } from "react-router-dom";
import { colors } from "../../..";
import { getPDF } from "./WeddingComponent";

export const WeddingStepper = (props) => {
  const { activeStep, setActiveStep } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invite = useSelector(selectInviteValues);
  const steps = invite.isUploaded
    ? ["Configurează aspectul", "Rezumat"]
    : ["Detalii", "Configurează aspectul", "Rezumat"];

  const handleGoBackToCustomise = () => {
    if (activeStep > 0) {
      setActiveStep((activeStep) => activeStep - 1);
    } else {
      // eslint-disable-next-line no-restricted-globals
      const result = confirm(
        "Esti sigur? Vei pierde tot ce ai lucrat pana acum"
      );
      if (result) {
        navigate("/wedding");
      }
    }
  };
  const handleNext = () => {
    activeStep < steps.length - 1 &&
      setActiveStep((activeStep) => activeStep + 1);
  };
  const matches = useMediaQuery("(min-width:900px)");

  const addWeddingFile = async () => {
    const file = await getPDF();
    dispatch(changeFileToSendValue(file));
    navigate("/paymentForm");
  };
  return (
    <Grid item container xs={12} height="10vh">
      <Grid
        item
        container
        display={{ xs: "flex", sm: "none" }}
        xs={6}
        padding="10px"
        alignContent="center"
        justifyContent="center"
      >
        <Button
          sx={{
            backgroundColor: colors.customYellow,
            color: colors.text,
            padding: "8px  24px 8px 24px",
            borderRadius: "20px",
            height: "3vh",
            boxShadow: " 2px 2px 2px black;",
          }}
          onClick={handleGoBackToCustomise}
        >
          Înapoi
        </Button>
      </Grid>
      {activeStep < steps.length - 1 && (
        <Grid
          item
          alignContent="center"
          container
          padding="10px"
          display={{ xs: "flex", sm: "none" }}
          xs={6}
          justifyContent="center"
        >
          <Button
            sx={{
              backgroundColor: colors.customYellow,
              color: colors.text,
              padding: "8px  24px 8px 24px",
              borderRadius: "20px",
              height: "3vh",
              boxShadow: " 2px 2px 2px black;",
            }}
            onClick={handleNext}
          >
            Înainte
          </Button>
        </Grid>
      )}
      {activeStep === steps.length - 1 && (
        <Grid
          item
          container
          xs={6}
          padding="10px"
          display={{ xs: "flex", sm: "none" }}
          alignContent="center"
          justifyContent="center"
        >
          <Button
            sx={{
              backgroundColor: colors.customYellow,
              color: colors.text,
              padding: "8px  24px 8px 24px",
              borderRadius: "20px",
              height: "3vh",
              boxShadow: " 2px 2px 2px black;",
            }}
            onClick={addWeddingFile}
          >
            Adauga in cos
          </Button>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={8}
        justifyContent="center"
        alignContent="center"
      >
        <Box sx={{ width: { xs: "auto", sm: "60%" } }}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel={!matches}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  color="inherit"
                  onClick={() => setActiveStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={2}
        display={{ xs: "none", sm: "flex" }}
        alignContent="center"
        justifyContent={{ xs: "unset", sm: "center" }}
      >
        <Button
          sx={{
            backgroundColor: colors.customYellow,
            color: colors.text,
            padding: "8px  24px 8px 24px",
            borderRadius: "20px",
            height: "3vh",
            boxShadow: " 2px 2px 2px black;",
          }}
          onClick={handleGoBackToCustomise}
        >
          Înapoi
        </Button>
      </Grid>
      {activeStep < steps.length - 1 && (
        <Grid
          item
          alignContent="center"
          container
          display={{ xs: "none", sm: "flex" }}
          xs={2}
          justifyContent={{ xs: "unset", sm: "center" }}
        >
          <Button
            sx={{
              backgroundColor: colors.customYellow,
              color: colors.text,
              padding: "8px  24px 8px 24px",
              borderRadius: "20px",
              height: "3vh",
              boxShadow: " 2px 2px 2px black;",
            }}
            onClick={handleNext}
          >
            Înainte
          </Button>
        </Grid>
      )}
      {activeStep === steps.length - 1 && (
        <Grid
          item
          container
          xs={2}
          display={{ xs: "none", sm: "flex" }}
          alignContent="center"
          justifyContent={{ xs: "unset", sm: "center" }}
        >
          <Button
            sx={{
              backgroundColor: colors.customYellow,
              color: colors.text,
              padding: "8px  24px 8px 24px",
              borderRadius: "20px",
              height: "3vh",
              boxShadow: " 2px 2px 2px black;",
            }}
            onClick={addWeddingFile}
          >
            Adauga in cos
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
