import { useState } from "react";
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, ACCESS_TOKEN } from "../constants";
import { login } from "../util/APIUtils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import { colors } from "../../..";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { changeUserValues } from "./loginSlice";
import { displayToastError } from "../../../utils/ErrorBoudary/errorBoudarySlice";
import BackgroundImage from "../../../assets/background-pattern-10opacity1x.png";

export const Login = (props) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  let route = location.state?.route;

  const handleSubmit = () => {
    const loginRequest = Object.assign(
      {},
      { email: email, password: password }
    );

    login(loginRequest)
      .then((response) => {
        if (response.accessToken) {
          localStorage.setItem(ACCESS_TOKEN, response.accessToken);
          dispatch(changeUserValues({ field: "authenticated", value: true }));
          if (route) {
            navigate(route);
          } else {
            navigate("/");
          }
        } else {
          dispatch(displayToastError(response.detail));
        }
      })
      .catch((error) => {});
  };

  const handleButtonClick = (routeToReturn) => {
    window.location.href = routeToReturn;
    window.sessionStorage.setItem("redirectRoute", route ? route : "");
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: `calc(100vh - 50px)`,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "black",
      }}
    >
      <Paper
        sx={{
          width: { xs: "100%", md: "30vw" },
          padding: "12px",
          border: "6px solid",
          borderColor: colors.customYellow,
          borderRadius: "16px",
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          marginBottom="12px"
        >
          <Typography variant="h4"> {i18next.t("login.button")}</Typography>
        </Grid>

        <Grid item container xs={12} spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              name="email"
              placeholder="Email"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value.trim())}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="password"
              name="password"
              placeholder={i18next.t("password")}
              fullWidth
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button
              onClick={handleSubmit}
              variant="contained"
              fullWidth
              color="dark"
              sx={{ border: "1px solid black" }}
            >
              {i18next.t("login.button")}
            </Button>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <span>
              {i18next.t("not.registered")}{" "}
              <Link style={{ color: "black", fontWeight: "bold" }} to="/signup">
                {i18next.t("register.here")}!
              </Link>
            </span>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <span>
              {i18next.t("forgot.password")}{" "}
              <Link
                style={{ color: "black", fontWeight: "bold" }}
                to="/resetPassword"
              >
                {i18next.t("reset.here")}
              </Link>
            </span>
          </Grid>
        </Grid>
        <Divider sx={{ padding: "12px" }} textAlign="center">
          {i18next.t("register.or")}
        </Divider>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <Button
              href={FACEBOOK_AUTH_URL}
              sx={{ alignItems: "center" }}
              variant="outlined"
              fullWidth
              startIcon={<FacebookIcon />}
            >
              Facebook
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{ textAlign: "center" }}
              onClick={() => handleButtonClick(GOOGLE_AUTH_URL)}
              variant="outlined"
              fullWidth
              startIcon={<GoogleIcon />}
            >
              Google
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Login;
