import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Gold from "../assets/gold.jpg";
import Silver from "../assets/silver.jpg";
import Bronze from "../assets/bronze.jpg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeInvitePriceValue,
  changeInviteValue,
  changeTextColorValue,
  clearTextsValue,
} from "./weddingSlice";
import { selectPricingList } from "../../Pricing/pricingSlice";

export const WeddingItemCard = (props) => {
  const { isSoftouch, titlu, texts, images, previewImages } = props;
  const navigate = useNavigate();
  const priceList = useSelector(selectPricingList);
  const [softouchPrice, setSoftouchPrice] = useState(0);
  const [folioType, setFolioType] = useState("G");
  const dispatch = useDispatch();

  useEffect(() => {
    const softouchPriceaux =
      priceList.length > 0 &&
      priceList?.find(
        (priceElement) => priceElement?.material === "InviteSoftTouch"
      )?.price;
    setSoftouchPrice(softouchPriceaux);
  }, [priceList]);

  const handleOnSelectInvite = () => {
    if (folioType === "N") {
      dispatch(changeInviteValue({ key: "folioType", value: "None" }));
      dispatch(
        changeInviteValue({ key: "image", value: images.invitatieSimpla })
      );
    } else if (folioType === "G") {
      dispatch(changeInviteValue({ key: "folioType", value: "Gold" }));
      dispatch(
        changeInviteValue({ key: "image", value: images.invitatieGold })
      );
    } else if (folioType === "S") {
      dispatch(changeInviteValue({ key: "folioType", value: "Silver" }));
      dispatch(
        changeInviteValue({ key: "image", value: images.invitatieSilver })
      );
    } else {
      dispatch(changeInviteValue({ key: "folioType", value: "Bronze" }));
      dispatch(
        changeInviteValue({ key: "image", value: images.invitatieBronze })
      );
    }
    dispatch(changeInviteValue({ key: "isUploaded", value: false }));
    dispatch(clearTextsValue());
    dispatch(changeTextColorValue("#000000"));
    dispatch(changeInviteValue({ key: "texts", value: texts }));
    dispatch(changeInviteValue({ key: "previewImages", value: images }));
    isSoftouch &&
      dispatch(
        changeInvitePriceValue({
          key: "softouch",
          value: softouchPrice,
        })
      );
    navigate("/customizeWedding");
  };

  return (
    <Grid
      item
      container
      style={{
        width: "220px",
        height: "440px",
        borderRadius: "8px",
      }}
      justifyContent={"center"}
    >
      {folioType === "G" && (
        <img
          src={previewImages.invitatieGold}
          alt="goldFolio"
          style={{
            width: "180px",
            height: "260px",
            marginTop: "10px",
            cursor: "pointer",

            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
          }}
        />
      )}
      {folioType === "S" && (
        <img
          src={previewImages.invitatieSilver}
          onClick={handleOnSelectInvite}
          alt="silverFolio"
          style={{
            width: "180px",
            height: "260px",
            marginTop: "10px",
            cursor: "pointer",

            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
          }}
        />
      )}
      {folioType === "B" && (
        <img
          src={previewImages.invitatieBronze}
          alt="bronzeFolio"
          onClick={handleOnSelectInvite}
          style={{
            width: "180px",
            height: "260px",
            marginTop: "10px",
            cursor: "pointer",

            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
          }}
        />
      )}
      {folioType === "N" && (
        <img
          src={previewImages.invitatieSimpla}
          alt="noFolio"
          onClick={handleOnSelectInvite}
          style={{
            width: "180px",
            height: "260px",
            marginTop: "10px",
            cursor: "pointer",
            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
          }}
        />
      )}
      <Grid item container xs={10}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          {titlu}
        </Typography>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Invitatie de nunta
        </Typography>
        {isSoftouch ? (
          <Typography sx={{ padding: "5px" }}>De la 2 RON</Typography>
        ) : (
          <Typography sx={{ padding: "5px" }}>De la 1,2 RON</Typography>
        )}
        <Grid xs={12} item container justifyContent={"space-between"}>
          <Grid xs={10} item container>
            <img
              src={Gold}
              alt="goldFolio"
              onClick={() => {
                dispatch(
                  changeInviteValue({ key: "folioType", value: "Gold" })
                );
                setFolioType("G");
              }}
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            <img
              src={Silver}
              onClick={() => {
                dispatch(
                  changeInviteValue({ key: "folioType", value: "Silver" })
                );
                setFolioType("S");
              }}
              alt="silverFolio"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            <img
              onClick={() => {
                dispatch(
                  changeInviteValue({ key: "folioType", value: "Bronze" })
                );
                setFolioType("B");
              }}
              src={Bronze}
              alt="bronzeFolio"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "5px",
                zIndex: "1000",
              }}
            />
            <div
              onClick={() => {
                dispatch(
                  changeInviteValue({ key: "folioType", value: "None" })
                );
                setFolioType("N");
              }}
              src={Bronze}
              alt="noFolio"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                marginRight: "5px",
                zIndex: "1000",
                background: "black",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <ArrowRightAltIcon
              style={{ cursor: "pointer" }}
              onClick={handleOnSelectInvite}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
