import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTextValue,
  selectInviteValues,
  selectTextValues,
} from "../weddingSlice";

export const WeddingOther = () => {
  const dispatch = useDispatch();
  const texts = useSelector(selectTextValues);
  const invite = useSelector(selectInviteValues);
  const findTextValue = (value) => {
    return invite.texts.filter((text) => text.id === value).length === 1;
  };
  return (
    <Grid item container xs={12} sm={11} paddingX={{ xs: "10px", sm: "0px" }}>
      <Grid xs={12} sm={4}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Altele
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          (Optional) Adăugați orice detalii suplimentare de care credeți că e
          nevoie
        </Typography>
      </Grid>
      <Grid item cotaniner xs={12} sm={8} alignContent="center">
        {findTextValue("confirmarePrezenta") && (
          <Grid xs={12} sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts.confirmarePrezenta}
              label="Confirmare prezenta"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "confirmarePrezenta",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. 29.07.2024"
              fullWidth
            />
          </Grid>
        )}
        {findTextValue("persoanaContact") && (
          <Grid xs={12}>
            <TextField
              value={texts.persoanaContact}
              label="Persoana de contact"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "persoanaContact",
                    value: event.target.value,
                  })
                );
              }}
              placeholder="ex. Andrada 07123123132"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
