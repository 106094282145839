import React from "react";
import { Grid } from "@mui/material";
import { WeddingInvite } from "./WeddingInvite";
import { jsPDF } from "jspdf";

let pdfFile;

const pdfFromReact = async (target, orientation) => {
  let pdf = new jsPDF(orientation, "px", [2000, 2100]);
  await pdf.html(document.querySelector(target), {
    callback: () => {
      // window.open(pdf.output("bloburl"));

      pdfFile = pdf.output("datauristring");
    },
  });

  return pdfFile;
};

export const getPDF = async () => {
  const file = await pdfFromReact("#divToPrint", "p");

  return file;
};

export const WeddingComponent = () => {
  // you can also use a function to return the target element besides using React refs

  return (
    <Grid
      container
      xs={12}
      sx={{ minHeight: { xs: "auto", md: "80vh" } }}
      justifyContent="center"
    >
      <Grid
        container
        item
        md={8}
        sx={{ height: { xs: "50vh", md: "80vh" }, backgroundColor: "#F6F6F6" }}
        justifyContent="center"
        alignContent="center"
      >
        <WeddingInvite />
      </Grid>
    </Grid>
  );
};
