import React from "react";
import { Grid, Typography } from "@mui/material";
import { WeddingEventDetails } from "./Wedding-EventDetails/WeddingEventDetails";
import { WeddingLocationDetails } from "./Wedding-EventDetails/WeddingLocationDetails";
import { WeddingChurchDetails } from "./Wedding-EventDetails/WeddingChurchDetails";
import { WeddingOther } from "./Wedding-EventDetails/WeddingOther";

export const WeddingDetailsStep = () => {
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={{ xs: "center", md: "flex-start" }}
      marginY="5vh"
      alignItems="flex-start"
    >
      <Typography
        sx={{ fontWeight: "bold", fontSize: "22px", paddingBottom: "15px" }}
      >
        Configurează detaliile
      </Typography>
      <WeddingEventDetails />
      <WeddingLocationDetails />
      <WeddingChurchDetails />
      <WeddingOther />
    </Grid>
  );
};
