import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Gold from "../../assets/gold.jpg";
import Silver from "../../assets/silver.jpg";
import Bronze from "../../assets/bronze.jpg";
import {
  changeInvitePriceValue,
  changeInviteValue,
  selectAdditionalsValue,
  selectInviteValues,
} from "../weddingSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectPricingList } from "../../../Pricing/pricingSlice";
export const WeddingFolio = () => {
  const dispatch = useDispatch();
  const invite = useSelector(selectInviteValues);
  const priceList = useSelector(selectPricingList);
  const additionals = useSelector(selectAdditionalsValue);

  useEffect(() => {
    const folioPrice =
      priceList.length > 0 &&
      priceList?.find(
        (priceElement) => priceElement?.material === "FolioInvite"
      )?.price;
    const nonFolioPrice =
      priceList.length > 0 &&
      priceList?.find((priceElement) => priceElement?.material === "NonFolio")
        ?.price;
    if (invite.folioType === "None") {
      dispatch(
        changeInvitePriceValue({
          key: "folio",
          value: nonFolioPrice,
        })
      );
    } else {
      dispatch(
        changeInvitePriceValue({
          key: "folio",
          value: folioPrice,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite, priceList]);

  console.log("invite.folioType", invite.folioType);
  return (
    <Grid item container xs={12} justifyContent={{ xs: "center", md: "unset" }}>
      <Typography sx={{ fontWeight: "bold", padding: "5px" }}>Folio</Typography>
      <Typography sx={{ padding: "5px" }}>
        Alege varianta potrivita de folio pentru invitatia ta
      </Typography>
      {invite.isUploaded && invite.folioType !== "None" && (
        <Typography sx={{ padding: "5px", fontSize: "12px", color: "#EC7070" }}>
          Pentru folio avem nevoie de formatul vectorial al invitatiei!
        </Typography>
      )}
      <Grid
        item
        container
        xs={12}
        gap={"16px"}
        sx={{ padding: "5px" }}
        justifyContent={{ xs: "center", md: "unset" }}
      >
        <Grid
          item
          container
          xs={4}
          sm={2}
          onClick={() => {
            dispatch(changeInviteValue({ key: "folioType", value: "Gold" }));
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            display: additionals.paperType === "line" ? "none" : "flex",
            border:
              invite?.folioType === "Gold"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <img
            src={Gold}
            alt="goldFolio"
            style={{
              width: "30%",
              height: "100%",
              borderRadius: "4px 0px 0px 4px",
            }}
          />
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>Auriu</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={2}
          onClick={() => {
            dispatch(changeInviteValue({ key: "folioType", value: "Silver" }));
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            display: additionals.paperType === "line" ? "none" : "flex",

            border:
              invite?.folioType === "Silver"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <img
            src={Silver}
            alt="goldFolio"
            style={{
              width: "30%",
              height: "100%",
              borderRadius: "4px 0px 0px 4px",
            }}
          />
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>Argintiu</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={2}
          onClick={() => {
            dispatch(changeInviteValue({ key: "folioType", value: "Bronze" }));
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            display: additionals.paperType === "line" ? "none" : "flex",

            border:
              invite?.folioType === "Bronze"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <img
            src={Bronze}
            alt="goldFolio"
            style={{
              width: "30%",
              height: "100%",
              borderRadius: "4px 0px 0px 4px",
            }}
          />
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>Cupru</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={4}
          sm={2}
          onClick={() => {
            dispatch(changeInviteValue({ key: "folioType", value: "None" }));
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            display: additionals.paperType === "black" ? "none" : "flex",

            border:
              invite?.folioType === "None"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <div
            alt="noFolio"
            style={{
              width: "30%",
              height: "100%",
              borderRadius: "4px 0px 0px 4px",
              background: "black",
            }}
          />
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography>Fara folio</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
