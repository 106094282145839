import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch } from "react-redux";
import { changeInviteValue, clearTextsValue } from "./weddingSlice";
import { useNavigate } from "react-router-dom";

export const WeddingUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUpload = (event) => {
    const image = URL.createObjectURL(event.target.files[0]);
    dispatch(changeInviteValue({ key: "image", value: image }));
    dispatch(changeInviteValue({ key: "isUploaded", value: true }));
    dispatch(clearTextsValue());
    navigate("/customizeWedding");
  };
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        border: "2px solid black",
        borderRadius: "20px",
        boxShadow: "2px 2px 0px 0px #000000",
      }}
      justifyContent={"center"}
    >
      <Grid xs={10}>
        <Typography
          sx={{ fontWeight: "bold", padding: "5px", marginTop: "10px" }}
        >
          Ai deja un design?
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          Nici o problemă, te ajutăm noi. Explorează opțiunile noastre de
          printare. Dar mai întâi arată-ne designul tau!
        </Typography>
      </Grid>

      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        onChange={(event) => handleUpload(event)}
        type="file"
      />
      <label htmlFor="raised-button-file">
        <Grid
          xs={12}
          item
          container
          sx={{
            fontWeight: "bold",
            padding: "5px",
            border: "2px dashed #7079EC",
            borderRadius: "12px",
            marginY: "15px",
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button component="span">
            <UploadIcon style={{ color: "#7079EC" }} />
            Încarcă designul tău
          </Button>
        </Grid>
      </label>
    </Grid>
  );
};
