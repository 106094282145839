import React from "react";
import { Grid, Typography } from "@mui/material";
import { CirclePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { changeTextColorValue, selectTextColorValue } from "../weddingSlice";

export const WeddingTextColor = () => {
  const dispatch = useDispatch();
  let colors = [
    "#FADF7F",
    "#5C82F3",
    "#ECEBE4",
    "#BEB8EB",
    "#F2D0CE",
    "#BA967A",
    "#CB9145",
    "#2E6A77",
    "#009688",
    "#4caf50",
    "#000000",
  ];
  const textColor = useSelector(selectTextColorValue);
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
        Culoarea textului
      </Typography>
      <Typography sx={{ padding: "5px" }}>
        Adauga un strop de culoare invitatiilor tale
      </Typography>

      <CirclePicker
        width="80vh"
        colors={colors}
        color={textColor}
        circleSpacing={8}
        onChange={(event) => dispatch(changeTextColorValue(event.hex))}
      />
    </Grid>
  );
};
