import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { WeddingUpload } from "./components/WeddingUpload";
import { WeddingItemCard } from "./components/WedingItemCard";
import { invites } from "./Invites/invites";
import { useDispatch } from "react-redux";
import { resetWeddingState } from "./components/weddingSlice";
import { Helmet } from "react-helmet";

export const WeddingPage = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(resetWeddingState());
  }, []);
  return (
    <Grid
      item
      container
      xs={12}
      flexDirection={"column"}
      padding={"50px 80px 20px 80px"}
    >
      <Helmet>
        <title>PrintWorks - Invitatii nunta</title>
        <meta
          name="description"
          content="
                Configurează invitațiile de nuntă exact așa cum le-ai visat! Alege dintr-o varietate de modele elegante, personalizează textul, fonturile, culorile și adaugă elemente grafice speciale."
        />
        <meta
          name="title"
          content="Printworks:Configurator Invitații de Nuntă  Creează Invitația Perfectă!"
        />
      </Helmet>
      <Grid item container xs={!2}>
        <Typography
          sx={{
            fontWeight: "bold",
            padding: "5px",
            fontSize: { xs: "20px", md: "45px" },
          }}
        >
          Invitații de
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            padding: "5px",
            fontSize: { xs: "20px", md: "45px" },
            color: "#7079EC",
          }}
        >
          Nuntă
        </Typography>
      </Grid>
      <Typography sx={{ padding: "5px", marginBottom: "20px" }}>
        Alege dintr-o largă varietate de modele de invitații de nuntă si
        customizează-le așa cum îți doresti!
      </Typography>
      <Grid
        item
        container
        xs={!2}
        gap={"10px"}
        justifyContent={"space-between"}
      >
        <Grid item container xs={12} md={3.5} height="50%">
          <WeddingUpload />
        </Grid>
        <Grid item container xs={12} md={8} justifyContent="center">
          {invites.map((invite) => (
            <WeddingItemCard
              isSoftouch={invite.isSoftouch}
              titlu={invite.titlu}
              texts={invite.texts}
              images={invite.images}
              previewImages={invite.previewImage}
            />
          ))}

          {/* <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard />
          <WeddingItemCard /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
