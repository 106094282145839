import { ACCESS_TOKEN, API_BASE_URL } from "./components/Auth/constants";

// export const sendEmailWithAttachement = async (sticker) => {
//   const response = await fetch(`${API_BASE_URL}/drive/sendMailWithAttachment`, {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify({
//       recipient: "gsdgocan@gmail.com",
//       msgBody: "tEst",
//       subject: "test subj",
//       originalSVG: "TEST",
//       composedSVG: sticker,
//     }),
//   });
//   if (response.status === 200) {
//     return response;
//   } else {
//     return { error: true };
//   }
// };

export const resetPassword = async (email) => {
  const response = await fetch(
    `${API_BASE_URL}/user/resetPassword?email=${email}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }
  );
  if (response.status === 200) {
    return response;
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const resetPasswordWithToken = async (password, token) => {
  const response = await fetch(`${API_BASE_URL}/user/changePassword`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: token,
      password: password,
    }),
  });
  if (response.status === 200) {
    return response;
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const saveFileToDrive = async (
  stickers,
  puzzles,
  totalPrice,
  userInformation,
  userBilling,
  hasCourier,
  discountCode
) => {
  const response = await fetch(`${API_BASE_URL}/order/upload`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      stickers: stickers,
      puzzles: puzzles,
      totalPrice: totalPrice,
      userInformation: userInformation,
      userBilling: userBilling,
      hasCourier: hasCourier,
      discountCode: discountCode,
    }),
  });
  if (response.status === 200) {
    return response.json().then((res) => {
      return res;
    });
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const saveWeddingToBackend = async (
  file,  
  weddingDetails,
  totalPrice,
  userInformation,
  userBilling,
  hasCourier
) => {
  const response = await fetch(`${API_BASE_URL}/order/wedding`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      stickers: [
        {
          pdfFile: file,
        },
      ],
      weddingDetails: weddingDetails,
      totalPrice: totalPrice,
      userInformation: userInformation,
      userBilling: userBilling,
      hasCourier: hasCourier,
    }),
  });
  if (response.status === 200) {
    return response.json().then((res) => {
      return res;
    });
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const getRequestHistory = async () => {
  const response = await fetch(`${API_BASE_URL}/order/getHistory`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const getPriceList = async () => {
  const response = await fetch(`${API_BASE_URL}/sticker/prices`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const getDiscountList = async () => {
  const response = await fetch(`${API_BASE_URL}/sticker/discounts`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const getUserInformation = async () => {
  const response = await fetch(`${API_BASE_URL}/userInfo/adresses`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const deleteUserAddress = async (id) => {
  const response = await fetch(`${API_BASE_URL}/userInfo/deleteId/${id}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};

export const saveNewUserAddress = async (newAddress) => {
  const response = await fetch(`${API_BASE_URL}/userInfo/adresses`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newAddress),
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: true, errorMessage: response.detail };
  }
};
