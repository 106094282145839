import React from "react";
import { Modal, Typography, Grid, Box } from "@mui/material";
import { colors } from "../../..";

export const AcceptTermsModal = (props) => {
  const { open, handleClose } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        border: "0px",
      }}
    >
      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        sm={6}
        padding="24px"
        sx={{
          background: colors.secondary,
          borderRadius: "12px",
          maxHeight: "50vh",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          overflowY: "scroll",
        }}
      >
        <Typography variant="h5" textAlign="center">
          Termeni si Condiții Generale
        </Typography>
        <Typography>
          1. GENERALITĂȚI <br />
          <br />
          www.printworks.ro reprezintă site-ul oficial al TASWEB SRL, cu sediul
          social in Cluj-Napoca, str. Mehedinti nr. 25 , ap. 6 județul Cluj,
          România, înregistrată la Registrul Comerțului sub nr. J12/3708/2023,
          CUI 48726629, denumit in cele ce urmează “TASWEB SRL”, (operator de
          date cu caracter personal).
          <br /> Prin intermediul acestui site se asigură, în mod gratuit,
          tuturor celor interesați de produsele TASWEB SRL, informații cu
          privire la acestea, precum si de a oferi posibilitatea plasării de
          comenzi online. Site-ul este operat si administrat de către TASWEB
          SRL. <br />
          Accesul la prezentul site precum si folosirea acestuia sunt permise
          numai in concordanta cu prevederile legale aplicabile in acest
          domeniu, precum și termenii si condițiile de mai jos.
          <br /> In cazul in care nu sunteți de acord cu condițiile de utilizare
          a site-ului, care implică și prelucrarea datelor cu caracter personal
          de către TASWEB SRL, va rugam sa nu utilizați acest site.
          <br /> Acceptarea termenilor si condițiilor este realizată, în
          momentul plasării de comenzi online și/sau creării Contului de Client,
          odată cu selectarea opțiunii "Am luat cunoștință si accept Termenii si
          Condițiile". Selectarea acesteia opțiuni indică faptul ca sunteți de
          acord cu respectarea termenilor si condițiilor TASWEB SRL pe care
          le-ați citit, le-ați înțeles si le-ați acceptat in integralitate.
          <br />
          <br />
          2. DEFINITII <br />
          <br />
          CLIENT – orice persoana fizica care a împlinit vârsta de 18 ani sau
          persoana juridica care accesează Site-ul www.printworks.ro , prin
          crearea si utilizarea unui Cont si care si-a dat acordul cu privire la
          prezentele Termeni si Condiții Generale. <br />
          COMANDA – reprezintă intenția cumpărătorului de a achiziționa Produse
          de pe Site transmisa Vânzătorului, prin intermediul Site-ului. <br />
          CONT – secțiunea din Site formata din câmpuri destinate completării cu
          adresa de e-mail si o parola care permite Clientului/Comparatorului
          transmiterea Comenzii. Contul poate să conține informații despre
          Client/Cumpărător, inclusiv un istoric al comenzilor acestuia. <br />
          CONTRACT – înseamnă prezentul document si care stabilește condițiile
          generale de vânzare online a produselor de către TASWEB SRL, precum si
          drepturile si obligațiile Vânzătorului si ale
          Clientului/cumpărătorului si produce efectele unui contract încheiat
          la distanta intre Vânzător si Cumpărător in condițiile OG 130/2000,
          începând cu momentul transmiterii de catre TASWEB SRL a confirmării
          acceptării Comenzii
          <br />
          COS DE CUMPARATURI – forma online a coșului compus din totalitatea
          Produselor selectate de catre Client/Cumpărător din Site in vederea
          achiziționării. <br />
          CUMPARATOR – Clientul care efectuează o Comanda. <br />
          ISTORIC DE CUMPARATURI – reprezintă totalitatea datelor stocate in
          legatura cu achizitiile anterioare conform bonurilor fiscale (de ex.
          valoare cumpărături, produse, identificare magazine/online, adresa
          magazinului, data, ora etc.) <br />
          PRODUS – orice bun, articol sau serviciu afișat pe site sub forma de
          materiale tipografice/autocolante care pot fi personalizate in
          momentul plasării comenzii si urmează a fi livrat către Cumpărător in
          baza comenzii acestuia. <br />
          SITE – domeniul www.printworks.ro, subdomeniile si aplicatiile mobile
          ale acestuia. <br />
          <br />
          3. CONTINUT
          <br />
          <br />
          Site-ul este specializat in vânzarea online, in principal a materiale
          tipografice/autocolante care pot fi personalizate direct de către
          Client. <br />
          Utilizatorilor acestui Site le este in mod expres interzis sa
          folosească atât site-ul cat si serviciile puse la dispoziție prin
          intermediul acestuia, in scopul încărcării, descărcării, publicării
          sau transmiterii oricăror informații al căror conținut este ilegal sau
          contrar bunelor moravuri si ordinii publice (de exemplu: este
          defăimător, ofensator, amenințător, abuziv, vulgar, obscen, afectează
          dreptul la intimitate al altor persoane, îndeamnă la violenta sau la
          ura rasiala, etnica ori are un caracter intimidant). <br />
          Prezentul document stabilește condițiile contractuale aplicabile
          tuturor achizițiilor efectuate prin intermediul Site-ului, in baza si
          cu aplicarea prevederilor OUG nr. 34/2014 care transpun la nivel
          național prevederile Directivei 2011/83/UE a Parlamentului European si
          a Consiliului din 25 octombrie 2011 privind drepturile consumatorilor
          la încheierea si executarea contractelor la distanta. <br />
          TASWEB SRL isi rezerva dreptul de a modifica conținutul, structura si
          modul de accesare a Site-ului in funcție de schimbările ce au loc cu
          privire la produsele si ofertele postate pe site si de propriile
          proceduri interne. Continuarea folosirii Site-ului prin comandarea si
          achiziționarea de produse se considera acceptul clientului. In cazul
          modificării prezenților Termeni si Condiții, TASWEB SRL va informa
          Clientul/Cumpărătorul cu privire la acest lucru, continuarea
          utilizării Contului fiind posibila numai in condițiile acceptării de
          către acesta a noilor Termeni si Condiții. <br />
          <br />
          4. INREGISTRAREA CLIENTULUI PE SITE <br />
          <br />
          Orice client, persoana fizica cu vârsta minima de 18 ani împlinită sau
          o persoana juridica se poate înregistra prin crearea unui cont,
          completând câmpurile existente pe site-ul www.printworks.ro. De
          asemenea, înregistrarea unei comenzi pe site necesita, in prealabil,
          crearea unui cont. De asemenea, înregistrarea unei Comenzi pe Site
          necesită, in prealabil, crearea unui Cont care sa conțină si o adresa
          de e-mail valida. <br />
          In vederea efectuării unei comenzi, persoana fizică care a împlinit
          vârsta de 18 ani, respectiv persoana juridica se va înregistra pe Site
          prin crearea unui Cont, indicând adresa sa de e-mail si definind o
          parolă de acces. Pentru a putea utiliza Contul in vederea
          înregistrării de Comenzi pe Site, Clientul va trebui sa furnizeze in
          Cont și o adresa de furnizare a Produselor. <br />
          In momentul înregistrării unei Comenzi pe Site, Cumpărătorul își da in
          mod implicit acordul cu privire la modalitatea in care TASWEB SRL ii
          va transmite detaliile legate de comanda (notificări) prin e-mail.{" "}
          <br />
          Contractul se considera încheiat in momentul in care Cumpărătorul va
          primi notificarea de expediere a Comenzii transmisa de TASWEB SRL prin
          intermediul poștei electronice. <br />
          Pot comanda online doar persoanele care selectează pentru livrarea
          comenzii o adresa din Uniunea Europeana si care se înregistrează pe
          site-ul www.printworks.ro conform celor de mai jos. Înregistrarea pe
          site, precum si plasarea unei comenzi implica acordul implicit al
          cumpărătorului cu privire la Termeni si Condiții si la modalitatea
          prin care i se vor comunica de catre TASWEB SRL detalii legate de
          Comenzi. <br />
          Pentru evitarea oricărui dubiu, înregistrarea si crearea unui Cont pe
          Site nu este permisa persoanelor care nu au împlinit vârsta de 18 ani.{" "}
          <br />
          Clientul este singurul responsabil pentru consecințele care decurg din
          utilizarea Site-ului, precum si pentru orice daune materiale,
          intelectuale, electronice sau de orice alta natura, in conformitate cu
          legislația aplicabila in domeniu, precum si in ceea ce privește
          informațiile pe care le pune la dispozitia TASWEB SRL. <br />
          TASWEB SRL își rezerva dreptul de a restricționa/suspenda
          posibilitatea Clientului/cumpărătorului de a plasa comenzi pe Site, in
          cazul in care considera ca acțiunile acestuia in Site i-ar putea cauza
          vreun prejudiciu, nu respecta prezentele Termeni si Condiții sau
          încălca legislația in vigoare. Intr-o astfel de situație,
          Clientul/cumpărătorul se poate adresa Departamentului de Relație cu
          Clienții al TASWEB SRL. <br />
          TASWEB SRL nu poate fi ținut răspunzător in nicio situație in care
          Clientul utilizează pentru a se înregistra/autentifica pe Site
          informații false/incorecte referitoare la vârstă sau permite preluarea
          livrărilor aferente comenzilor efectuate pe site de către persoane
          care nu au împlinit vârstă de 18 ani. <br />
          TASWEB SRL poate publica pe Site informații despre Produse si/sau
          promoții valabile într-o anumită perioada de timp si in limita
          stocului disponibil. TASWEB SRL își rezerva dreptul de a refuza orice
          Comanda din motive legitime. <br />
          Toate informațiile folosite pentru descrierea Produselor disponibile
          pe Site (imagini statice / dinamice / prezentări multimedia / etc.)
          sunt cu titlu de prezentare, nu reprezintă o obligație contractuala a
          Vânzătorului, ci exclusiv o modalitate de prezentare, avânda scop
          informativ iar produsele livrate pot diferi de Produsele si
          descrierile prezentate pe Site. <br />
          <br />
          5. COMANDA ONLINE
          <br />
          <br />
          Pentru a plasa o comanda online in Site, Clientul/cumpărătorul trebuie
          sa parcurgă următorii pași: <br />• Sa se autentifice iar in cazul in
          care Clientul nu dispune deja de un Cont de Client in Site, acesta
          trebuie sa își creeze un Cont. <br />
          • Sa editeze produsul in manierea dorită. Se selectaza dimensiunea,
          cantitatea,culoarea și materialul din care se dorește producerea
          sticker-ului. <br />
          • Adaugă in Cos ori de cate ori identifica Produse pe care dorește să
          le comande imediat. Produsele adăugate vor fi afișate in secțiunea
          “coșul de cumpărături” din partea superioara a site-ului. <br />
          • Odată adăugat in coșul de cumpărături, un Produs este disponibil
          pentru achiziție in măsura in care exista stoc disponibil din
          materiale tipografice necesare pentru aceasta. Adăugarea unui Produs
          in coșul de cumpărături, in lipsa finalizării Comenzii, nu atrage după
          sine înregistrarea unei comenzi, implicit nici rezervarea automata a
          Produsului. <br />
          • Sa selecteze modalitatea de plata: online cu cardul, plata prin
          banca, numerar la livrare si sa specifice daca dorește factura pentru
          comanda sa. <br />
          • Clientul poate sa verifice detaliile comenzii: valoare comanda, tip
          de livrare, interval de livrare, data de livrare si modalitatea de
          plata. După trimiterea comenzii sau după ce datele bancare au fost
          verificate de Procesatorul de plăti, Clientul primește un e-mail de
          confirmare a lansării comenzii, pe adresa de mail asociata contului
          sau. <br />
          • Precizam ca mesajul de confirmare a lansării comenzii nu inseamna
          acceptarea comenzii de către TASWEB SRL, ci doar confirma faptul ca
          TASWEB SRL a recepționat comanda lansata de Client. Confirmarea
          acceptări comenzii va avea loc ulterior prin transmiterea de către
          TASWEB SRL a e-mailului de confirmare a expedierii si emiterea bonului
          fiscal aferent valorii finale a expediției. Contractul se va referi
          numai la acele produse enumerate în Confirmarea de expediere. <br />
          • Expedierea comenzii depinde de disponibilitatea materialelor
          tipografice în stocurile TASWEB SRL. În eventualitatea în care apar
          dificultăți de aprovizionare sau dacă produsele nu se mai află în
          stoc, TASWEB SRL va putea informa Clientul cu privire la înlocuirea
          produselor cu produse de calitate si valoare egale aflate in stoc.
          Dacă nu se dorește înlocuirea produselor și in situația in care
          Clientul a făcut deja plata aferentă produselor comandate, se va
          elibera integral orice suma de bani plătita in baza Comenzii. <br />
          Preturile afișate pe Site sunt informative si pot fi modificate in mod
          unilateral de TASWEB SRL. Nicio modificare a preturilor de către
          TASWEB SRL nu va afecta o comanda confirmata de TASWEB SRL. Preturile
          nu includ costurile de livrare. <br />
          Prin finalizarea Comenzii, Clientul consimte ca toate datele furnizate
          de acesta, necesare procesului de cumpărare, sunt corecte si complete,
          inclusiv adresa de livrare si confirma ca a împlinit minim 18 ani.{" "}
          <br />
          Fiecărei comenzi plasate in site i se va atribui un număr unic de
          comanda, număr ce poate fi folosit in urmărirea si identificarea
          acesteia. <br />
          Comanda odată plasata nu poate fi modificata, Clientul având
          următoarele posibilități: <br />• Comanda poate fi anulată dacă:
          fișierele nu au fost încărcate, plata nu a fost efectuată, lucrarea nu
          a intrat în procesul de pre-execuție. <br />
          • Comanda nu mai poate fi anulată dacă a intrat deja în procesul de
          producție. <br />
          Anularea unei Comenzi, respectiv plasarea unei Comenzi noi, in aceasta
          situatei va avea loc cu respectarea pașilor descriși in prezentul
          document. <br />
          Plasarea unei Comenzi implica acceptarea de către Client a preturilor
          si a descrierii Produselor oferite spre vânzare. Clienții sunt
          încurajați sa citească caracteristicile/ descrierea fiecărui Produs
          pentru a-i cunoaște caracteristicile, iar pentru informații complete
          sa verifice etichetarea Produselor la ridicarea Comenzii si înainte de
          orice consum sau utilizare, iar, daca este necesar, sa facă uz de
          dreptul lor de retractare. <br />
          TASWEB SRL poate anula Comanda efectuata de către Cumpărător,
          notificând cumpărătorului in acest sens, fără nicio obligație
          ulterioara a vreunei părți fata de cealaltă sau fără ca vreo parte sa
          poată sa pretindă celeilalte daune-interese in următoarele cazuri:{" "}
          <br />
          • neacceptarea de către banca emitenta a cardului comparatorului, a
          tranzacției, in cazul plații online; <br />
          • invalidarea tranzacției de către Netopya/procesatorul de carduri
          agreat de TASWEB SRL, in cazul plății online; <br />
          • datele furnizate de către Client/Cumpărător, pe Site sunt incomplete
          si/sau incorecte; <br />
          • lipsa produselor in stoc din cauze cum ar fi, dar fără a se limita
          la: deteriorare ambalaj, deficiente de calitate, deficiente etichetare
          etc. <br />
          cumpărătorul are de asemenea dreptul de a anula comanda, prin
          intermediul site-ului TASWEB SRL dacă aceasta nu a ajuns deja in
          producție. <br />
          In cazul in care un Produs comandat de către Cumpărător, nu poate fi
          livrat de către Vânzător, acesta din urma va informa
          Clientul/cumpărătorul asupra acestui fapt si, se va elibera in contul
          cumpărătorului contravaloarea Produsului, in termen de maximum 14 zile
          de la data transmiterii notificării. <br />
          TASWEB SRL realizează toate demersurile necesare pentru eliberarea in
          contul Cumpărătorilor a contravalorii Produselor/ Comenzilor care nu
          pot fi livrate sau sunt anulate de către oricare dintre părți, din
          varii motive, urmând ca sumele aferente sa fie eliberate
          Cumpărătorilor după procesarea tranzacțiilor de către procesatorul de
          plăți si instituțiile bancare participante in tranzacție. <br />
          Timpul de livrare al stickere-lor personalizate poate varia în funcție
          de mai mulți factori, inclusiv locația dvs., cantitatea comandată și
          procesul specific de producție al furnizorului. Autocolantele se
          livreaza cu ajutorul firmelor de curierat rapid astfel in general
          termenul de livrare este de 6-7 zile lucratoare. <br />
          6. MODALITATI DE PLATA SI PRETURI
          <br />
          <br />
          Prețul si modalitatea de plata sunt specificate in fiecare Comanda.
          Prețul de vânzare al Produselor este cel in vigoare la momentul
          plasării Comenzii, este exprimat in LEI (RON) si include TVA. TASWEB
          SRL isi desfășoară operațiunile in LEI și nu va aplica niciun comision
          suplimentar si nicio suprataxa pentru tranzacțiile aferente
          comenzilor. <br />
          Dacă tranzacțiile prezinta caracter internațional, datorită faptului
          că banca emitentă a cardului Cumpărătorului și/sau procesatorul de
          plăți si banca acceptanta se afla într-un alt stat, este posibil sa
          apară anumite costuri pentru Cumpărător, ca urmare a schimburilor
          valutare sau comisioanelor bancare pentru tranzacții internaționale,
          conform Termenilor si Condițiilor agreate intre banca emitenta si
          consumator. Din acest motiv, in cazul plății cu cardul, daca sumele
          debitate de pe cardul unui Cumpărător in legătură cu o tranzacție sau
          rambursate pe card diferă de prețul afișat la finalizarea comenzii sau
          de suma confirmata care urma sa fie rambursata, rugam cumpărătorul sa
          contacteze banca emitenta a cardului, pentru a primi mai multe
          informații referitoare la comisioanele bancare si costurile de schimb
          valutar. <br />
          La solicitarea cumpărătorului, TASWEB SRL va putea emite si transmite
          cumpărătorului factura fiscala aferenta Comenzii, in condițiile
          transmiterii de carte Client a datelor necesare conform legii pentru
          întocmirea acesteia urmând ca TASWEB SRL sa emită factura fiscala in
          conformitate cu datele transmise de către Client. <br />
          Facturarea produselor achiziționate se face exclusiv in LEI. <br />
          Conform art. 319, din Codul Fiscal, TASWEB SRL își rezerva dreptul de
          a emite facturi simplificate, in cazul in care valoarea facturilor
          solicitate de către nu este mai mare de 100 de euro (alin. 12).
          Astfel, sunt considerate facturi „documentele sau mesajele pe suport
          hârtie ori în format electronic”, precum și „orice document sau mesaj
          care modifică și care se referă în mod specific și fără ambiguități la
          factura inițială”. <br />
          Elementele pe care o factură simplificată trebuie să le cuprindă sunt
          următoarele: <br />
          • data emiterii; <br />
          • identificarea persoanei impozabile care a livrat bunurile sau a
          prestat serviciile; <br />
          • identificarea tipului de bunuri sau servicii furnizate; <br />
          • suma taxei colectate sau informațiile necesare pentru calcularea
          acesteia. <br />
          Pentru celelalte cazuri în care este obligatorie emiterea unei facturi
          normale, se aplică prevederile art. 319, (alin. 20) privind elementele
          obligatorii ce trebuie cuprinse pe o factură. <br />
          Bonul fiscal va fi eliberat pentru fiecare comanda in parte si va
          însoți produsele livrate. <br />
          Cumpărătorul are la dispoziție următoarele modalități de plata pentru
          comenzile efectuate pe Site: <br />
          • plata online cu Cardul Bancar
          <br />
          In cazul plății prin Card Bancar, plățile sunt procesate prin
          intermediul Platformei NETOPIA Payments, administrată de către
          societatea NETOPIA FINANCIAL SERVICES S.R.L., înregistrată la
          Registrul Comerțului sub nr. J40/12763/2020, cod unic de înregistrare
          RO43131360, cu sediul în Bd. Dimitrie Pompeiu 9-9A, IRIDE BUSINESS
          PARK, Clădirea 24, etaj 4, Sector 2, București, 020335 NETOPYA., in
          calitate de procesator de plăți. <br />
          TASWEB SRL va debita contul curent al Clientului cu sumele
          reprezentând contravaloarea Produselor comandate si livrate, conform
          mesajului de confirmare a Comenzii transmise către Cumpărător. In
          cazul plății in numerar la livrare, TASWEB SRL va incasa sumele
          reprezentând contravaloarea Produselor comandate si taxa de livrare
          prin reprezentantul firmei de curierat, in momentul livrării comenzi.{" "}
          <br />
          In cazul plaților online, TASWEB SRL nu este / nu poate fi făcut
          responsabil pentru niciun alt cost suplimentar suportat de Client,
          incluzând, dar nelimitând-se la comisioane de conversie valutara
          aplicate de către banca emitenta a cardului acestuia, in cazul in care
          moneda de emitere a acestuia diferă de LEI. Responsabilitatea pentru
          aceasta acțiune o poarta Clientul in mod exclusiv. In cazul in care se
          constata o eroare in procesarea plății care duce la neprocesarea
          acesteia sau la procesarea eronata a acesteia, TASWEB SRL își rezerva
          dreptul de a retrage suma aferenta comenzii livrate si neachitate in
          termen de 30 zile de la momentul identificării erorii de procesare a
          comenzii, cu transmiterea unei informări Clientului in acest sens.{" "}
          <br />
          In situația in care, ca urmare a unor erori tehnice TASWEB SRL
          livrează cumpărătorului comanda parțială, sub valoarea comenzii
          inițiale achitate prin Card Bancar, cumpărătorul poate contacta TASWEB
          SRL in vederea livrării si completării comenzii inițiale si achitate
          deja. <br />
          Exemple de situații in care valoarea Comenzii poate sa scadă: <br />
          • lipsa produselor in stoc (deteriorare material tipografic,
          deficiente de calitate, deficiente etichetare etc.). <br />
          7. LIVRAREA BUNURILOR
          <br />
          <br />
          Conform opțiunii Clientului, Produsele comandate de Client vor fi
          livrate in sistem de curierat la adresa indicata de Cumpărător sau
          către punctele de ridicare a comenzilor, conform opțiunii
          cumpărătorului. <br />
          TASWEB SRL va asigura ambalarea corespunzătoare a trimiterilor poștale
          înainte de preluarea acestora de către partenerii contractați pe
          platforma Ecolet, in vederea evitării deteriorării, distrugerii,
          livrării greșite, dar si in scopul inviolabilității acestora. <br />
          Toate informațiile referitoare la valoarea comenzii, taxa de livrare,
          precum si informații privind eventualele reduceri sunt disponibile pe
          site, in secțiunea „Coșul meu” la plasarea comenzii. <br />
          Produsele comandate vor fi livrate la adresa indicata de către Client
          in procesul efectuării comenzii. <br />
          Clientul are obligația de a verifica corectitudinea informațiilor
          furnizate cu privire la adresa de livrare anterior validării comenzii.
          In cazul apariției unor erori la introducerea datelor de livrare
          (strada, număr strada, bloc, număr, etaj, persoana de contact, număr
          de telefon) TASWEB SRL nu va fi făcut responsabil pentru
          imposibilitatea livrării comenzii. <br />
          TASWEB SRL / Curierul poate refuza livrarea comenzii in situația in
          care Clientul nu se (mai) afla in aria de livrare sau modifica adresa
          de livrare in afara ariei de livrare după confirmarea comenzii, fără
          nicio obligație ulterioara a TASWEB SRL fata de Client sau fără ca
          acesta sa poată pretindă daune-interese TASWEB SRL. <br />
          La solicitarea curierului, Clientul va face dovada identității sale si
          va comunica numărul de comanda. <br />
          In cazul in care Clientul se afla in imposibilitatea recepționarii
          comenzii, comanda va fi lăsata la adresa precizata doar unei persoane
          cu vârstă împlinită de minim 18 ani si doar in cazul comunicării către
          curier a numărului comenzii. <br />
          O comandă nu va putea fi livrată dacă un Client nu îndeplinește
          condițiile mai sus menționate
          <br />. Clientul trebuie sa se asigure ca poate recepționa comanda
          efectuata pe Site si facilitează accesul Curierului in locația
          menționată in comanda. In cazul in care Curierul nu poate avea acces
          la adresa comunicata, livrarea nu se va onora. <br />
          Livrarea se considera a fi îndeplinită de catre TASWEB SRL, la
          momentul predări produselor comandate către Client la adresa selectata
          de acesta la momentul validării comenzii. <br />
          In eventualitatea depășirii Intervalului de timp prevăzut pentru
          Ridicare/Livrare, TASWEB SRL sau reprezentantul firmei de curierat va
          informa Clientul prin SMS sau telefon si va agrea cu acesta
          prelungirea termenului de livrare cu o noua perioada. <br />
          <br />
          8. TAXE DE LIVRARE
          <br />
          <br />
          Taxa de livrare va fi afișata atât in momentul plasării comenzii, cat
          si după finalizarea acesteia. <br />
          TASWEB SRL poate sa ofere livrarea gratuita a Comenzilor care depășesc
          o anumita valoare sau care contin anumite produse, indiferent de
          valoarea totala a Comenzii. <br />
          In situația in care la data Confirmării Comenzii, valoarea Comenzii
          Confirmate scade sub valoarea comenzii pentru care se ofera livrarea
          gratuita, TASWEB SRL va factura Clientilor contravaloarea taxei de
          livrare. <br />
          <br />
          9. GARANTII SI RETURUL PRODUSELOR
          <br />
          <br />
          9.1 Garantii
          <br />
          TASWEB SRL, acorda garantie conform prevederilor Legii 449/2003
          privind vânzarea produselor si garantiile asociate acestora („Legea
          nr. 449/2003"). <br />
          La receptionarea comenzii, clientul este incurajat sa se asigure ca
          produsele livrate corespund din punct de vedere calitativ si
          cantitativ asteptarilor sale. <br />
          Defectele cauzate de manevrarea brutala (inclusiv pe timpul
          transportului prin curierat- daca nu se ridica personal),defectele
          cauzate de obiecte ascutite, de indoire, comprimare sau cadere, etc.)
          nu fac obiectul garantiei. <br />
          Nu beneficiaza de garantiei producesele care au fost folosite
          inadecvat sau in alte scopuri, alterate sau curatate prin metode
          improprii sau cu substante neadecvate. De asemenea garanția nu
          acoperă: • Tăieturile, incendierea, deteriorarea, furtul, etc. •
          Decolorarea produsului sau a unor parti componente in urma expunerii
          la soare sau o alta sursa puternica de căldura sau in urma contactului
          cu apa. • Vopsirea produsului de catre client, deteriorări in urma
          unor calamități. • Ruperea / zgârierea produsului ca urmare a lovirii
          sale, curățarea cu substanțe neadecvate. Termenul de garantie
          comerciala a produselor incepe la data mentionata in factura fiscala
          care atesta vânzarea. In conformitate cu art. 11 din Legea 449/2003
          fiecare Client poate solicita, in cazul lipsei conformitatii, in
          primul rand repararea Produsului sau poate solicita inlocuirea
          Produsului, in fiecare caz fara plata, cu exceptia situatiei in care
          masura este imposibila sau disproportionata. Inlocuirea Produsului
          este posibila in limita stocului disponibil. In cazul in care Clientul
          a returnat un Produs deoarece acesta prezenta defecte de fabricatie,
          iar Vanzatorul nu dispune de un produs inlocuitor corespunzator,
          acesta va returna Clientului contravaloarea Produsului respectiv.
          Clientul poate returna in termen de 24 de ore produsele achizitionate,
          in urmatoarele situatii: • Ambalajul prezinta deteriorari severe; •
          Produsele prezinta defecte de fabricatie; • Produsele nu respecta
          continutul mentionat in momentul Comenzii; • Produse cu defecte
          calitative. TASWEB SRL poate sa refuze Produsele returnate de catre
          Clientii sai, daca returul se incadreaza intr-unul din cazurile
          prevazute in continuare, fara ca acesta din urma sa poata pretinde
          daune-interese sau despagubiri: • In cazul inlocuirii Produsului
          achizitionat cu un alt Produs sau de un tip diferit, • In cazul in
          care Produsul returnat nu este in aceeasi stare in care a fost livrat
          (ex. in ambalajul original cu toate accesoriile, etichetele intacte si
          documentele care l-au insotit). In cazul in care aducerea la
          conformitate sau inlocuirea Produselor neconforme nu este posibila,
          rambursarea contravalorii Produselor respective se va face in termen
          de cel mult 72 de ore de la returnarea produsului neconform. Clauze
          pentru rambursarea sumelor aferente comenzilor/produselor returnate de
          client: Suma va fi returnata dupa cum urmeaza: a) pentru comenzile
          achitate cu card bancar, prin restituire in contul din care a fost
          efectuata plata, sau prin generarea unui voucher cu valoarea
          produsului returnat, cu conditia ca optiunea sa fie comunicata de
          catre client in avans. 9.2 Dreptul de retragere In conformitate cu
          art. 9 alin. 1 din Ordonanta de urgenta nr. 34/2014 privind drepturile
          consumatorilor in contractele incheiate cu profesionistii, precum si
          pentru modificarea si completarea unor acte normative („OUG nr.
          34/2014”), Clientul are dreptul de a denunta unilateral Contractul, in
          termen de 14 (paisprezece) zile, fara penalitati si fara invocarea
          vreunui motiv. Termenul de 14 zile curge de la data in care Clientul
          sau o parte terta, alta decat transportatorul si care este indicata de
          Client, intra în posesia fizica a produselor. In acest caz,
          cheltuielile directe de returnare a Produselor vor cadea, conform
          legii, in sarcina Clientului. Cu toate acestea, in functie de
          Produsele comandate, in conformitate cu prevederile art 16 din OUG nr.
          34/2014, se vor aplica restrictiile prevazute de art. 9.2.1 de mai
          jos. <br />
          Clientul isi poate exercita dreptul de retragere printr-o declaratie
          neechivoca, in una din urmatoarele modalitati: <br />
          • prin prin email la adresa office@printworks.ro , sau posta la adresa
          Mehedinti nr.25, ap.6, Cluj-Napoca, jud. CLuj. <br />
          • prin contactarea telefonica la numarul 0744452297. <br />
          In situatia in care Clientul notifica intentia de denuntare
          unilaterala a Contractului conform prezentului capitol, Vanzatorul are
          obligatia de a rambursa sumele platite de catre Client, inclusiv
          costurile livrarii, fara intarziere nejustificata, dar nu mai tarziu
          de 14 zile de la data la care este informat de decizia de retragere
          din contract a Clientului. TASWEB SRL ramburseaza sumele
          antementionate folosind aceeasi modalitate de plata ca si cele
          folosite de Cumparator pentru tranzactia initiala. <br />
          9.2.1 Criterii privind returnarea produselor <br />
          Produsele achizitionate pot fi returnate in baza exercitarii dreptului
          de retragere cu conditia ca: <br />
          • lucrarea poate fi returnată în 24 de ore de la primire, numai dacă
          nu se potrivete cu comanda si fisierele pe care le-ati trimis
          <br />
          • tinand cont că PRINTWORKS oferă servicii la comanda dumneavoastră și
          lucrările sunt executate special pentru dumneavoastră, acestea NU sunt
          returnabile ca orice bun de larg consum. <br />
          <br />
          10. FRAUDA
          <br />
          <br />
          TASWEB SRL nu solicita Clientilor sai prin niciun mijloc de comunicare
          (e-mail/telefonic/SMS/ etc) informatii referitoare la date
          confidentiale de tip conturi/carduri bancare sau parole personale.{" "}
          <br />
          Clientul isi asuma intreaga responsabilitate pentru divulgarea unui
          tert a datelor sale confidentiale. <br />
          TASWEB SRL isi declina orice responsabilitate, in situatia in care un
          Client ar fi/ este prejudiciat sub orice forma de catre un tert care
          ar pretinde ca este/reprezinta interesele TASWEB SRL. Clientul va
          informa TASWEB SRL asupra unor asemenea tentative, folosind datele de
          contact indicate de TASWEB SRL. <br />
          TASWEB SRL nu promoveaza SPAM-ul. Orice Client care a furnizat
          explicit adresa sa de e-mail in Magazinul Web poate opta pentru
          dezactivarea Contului de Client aferent acestei adrese de e-mail, sau
          pentru dezabonare de la primirea de newsletter-e/alte comunicari
          comerciale/promotionale. <br />
          Comunicarile realizate de catre TASWEB SRL prin mijloace electronice
          de comunicare la distanta (i.e. e-mail) contin datele de identificare
          complete si conforme ale expeditorului sau legaturi catre acestea, la
          data transmiterii continutului. <br />
          <br />
          11. FORTA MAJORA
          <br />
          <br />
          Niciuna din parti nu va fi raspunzatoare pentru neexecutarea sau
          executarea necorespunzatoare a obligatiilor sale contractuale, daca o
          astfel de neexecutare la termen si/sau in mod corespunzator, total sau
          partial este datorata unui eveniment de forta majora. Forta majora
          este evenimentul imprevizibil, in afara controlului partilor si care
          nu poate fi evitat. <br />
          Daca in termen de 15 (cincisprezece) zile de la data producerii lui,
          respectivul eveniment nu inceteaza fiecare parte va avea dreptul sa
          notifice celeilalte parti incetarea de plin drept a Contractului fara
          ca vreuna dintre ele sa poata pretinde celeilalte alte daune-interese.{" "}
          <br />
          <br />
          12. LIMITARE DE RESPONSABILITATE <br />
          <br />
          TASWEB SRL nu poate fi tinut responsabil in nici un fel in fata
          niciunui Client care utilizeaza Site-ul sau continutul acestuia,
          altfel decat in limita prevederilor inscrise in cadrul Termenilor si
          Conditiilor. <br />
          TASWEB SRL nu garanteaza Clientului acces la Serviciul oferit prin
          intermediul Site-ului, in lipsa inregistrarii de catre acesta din
          urma, prin parcurgerea etapelor de inregistrare mentionate in cadrul
          Termenilor si Conditiilor si nu ii confera dreptul de a descarca sau
          de a modifica partial si/sau integral Continutul, de a reproduce
          partial sau integral Continutul, de a copia, sau de a exploata orice
          Continut in orice alta maniera sau de a transfera vreunui tert orice
          Continut asupra caruia are si/sau a obtinut acces, in baza unui acord
          de utilizare, fara acordul prealabil scris al TASWEB SRL. <br />
          Site-ul www.printworks.ro poate contine legaturi conexe (numite
          hyperlink-uri sau link-uri), care permit conectarea automata la
          diferite site-uri de Internet. Aceste site-uri conexe sunt
          proprietatea unor terti si sunt administrate de acestia. TASWEB SRL nu
          raspunde de continutul, calitatea sau natura altor Site-uri la care se
          ajunge prin legaturi din Continut, indiferent de natura acestor
          legaturi. Pentru respectivele Site-uri, raspunderea o poarta,
          integral, proprietarii acestora. <br />
          TASWEB SRL este exonerat de orice vina in cazul utilizarii Site-urilor
          si/sau al Continutului transmis catre un Client, prin orice mijloc
          (electronic, telefonic, etc.), prin intermediul Site-urilor,
          e-mail-ului sau al unui angajat al TASWEB SRL, atunci cand aceasta
          utilizare a Continutului poate sau produce pagube de orice natura
          Clientului si/sau oricarui tert implicat in acest transfer de
          Continut. <br />
          TASWEB SRL nu ofera niciun fel de garantii directe sau indirecte ca:{" "}
          <br />
          • Produsul achizitionat va fi potrivit cerintelor Clientului; <br />
          • Produsele/Serviciile vor corespunde cerintelor sau asteptarilor
          Clientului; <br />
          In limita prevederilor Termenilor si Conditiilor, operatorii,
          administratorii si/sau proprietarii website-ului nu sunt sub nici o
          forma responsabili pentru relatiile sau consecintele acestora
          rezultand din, dar nelimitandu-se la, achizitii, oferte speciale,
          promotii, promovari, sau oricare alt tip de relatie/ legatura/
          tranzactie/ colaborare/ etc. care pot aparea intre Client si oricare
          dintre cei care isi fac promovare directa sau indirecta prin
          intermediul Magazinelor Web. <br />
          TASWEB SRL nu este responsabila pentru prejudiciile ce pot fi create
          ca urmare a folosirii neautorizate a informatiilor de pe Site. <br />
          TASWEB SRL nu este responsabila pentru eventualele costuri si/sau
          pierderi de orice fel suportate/rezultate ca urmare a folosirii
          informatiilor de pe Site. <br />
          Informatiile prezentate in paginile Produselor sau in sectiunile
          "Promotii" si “Produse noi” nu creeaza obligatii in sarcina TASWEB SRL
          in sensul ca TASWEB SRL nu isi asuma raspunderea pentru eventualele
          greseli de text sau pentru preturile postate. Oferta produselor
          prezentate in aceste sectiuni este valabila in limita stocului
          disponibil. <br />
          <br />
          13. RASPUNDERE
          <br />
          <br />• TASWEB SRL nu poate fi responsabil pentru daune de orice fel
          pe care Cumparatorul sau oricare terta parte le poate suferi ca
          rezultat al indeplinirii de catre TASWEB SRL a oricareia dintre
          obligatiile sale conform Comenzii si pentru daune care rezulta din
          utilizarea Produselor dupa livrare si in special pentru pierderea
          acestora. <br />
          • Prin crearea si utilizarea Contului, Clientul / Utilizatorul/
          Cumparatorul isi asuma raspunderea pentru mentinerea
          confidentialitatii datelor de Cont (user si parola) si pentru
          gestionarea accesarii Contului si, in masura permisa de legislatia in
          vigoare este responsabil de activitatea derulata prin intermediul
          Contului sau. <br />• Prin crearea si/sau plasarea Comenzilor,
          Clientul / Utilizatorul/ Cumparatorul accepta in mod expres si fara
          echivoc Termenii si conditiile Site-ului in ultima versiune
          actualizata care este comunicata in cadrul Site-ului, existenta la
          data crearii Contului si/sau utilizarii continutului si/sau la data
          plasarii Comenzii. <br />
          <br />
          14. LEGEA APLICABILA – JURISDICTIA
          <br />
          Prezentul Contract este supus legii romane. Eventualele litigii
          aparute intre TASWEB SRL si Clienti / Cumparatori se vor rezolva pe
          cale amiabila sau, in cazul in care acest lucru nu va fi posibil,
          litigiile vor fi solutionate de instantele judecatoresti competente.{" "}
          <br />
          Daca oricare dintre clauzele de mai sus va fi gasita nula sau
          nevalida, indiferent de cauza, aceasta clauza nu va afecta
          valabilitatea celorlalte clauze. <br />
          <br />
          15. PUBLICITATE
          <br />
          <br />
          In momentul in care Clientul isi creaza un Cont pe Site are
          posibilitatea sa isi exprime acordul cu privire la primirea de mesaje
          promotionale. Optiunea cu privire la acordul emis de catre Client,
          poate fi modificata in orice moment, folosind legatura special
          destinata din cadrul oricaror Newslettere sau prin debifarea optiunii
          existente in contul de client. <br />
          De asemenea, TASWEB SRL va transmite catre Client mesaje de confirmare
          prin e-mail sau sms, in urmatoarele situatii: <br />
          • crearea Contului (e-mail); <br />
          • schimbarea parolei Contului (e-mail); <br />
          • mesaj de confirmare Comanda, dupa lansarea comenzii (e-mail); <br />
          <br />
          16. DREPTURILE DE PROPRIETATE INTELECTUALA SI INDUSTRIALA
          <br />
          <br />
          Continutul Site-ului incluzand, dar nelimitandu-se la logo-uri,
          reprezentari stilizate, simboluri comerciale, imagini statice, imagini
          dinamice, text si/sau continut multimedia, sunt proprietatea exclusiva
          a TASWEB SRL, acesteia fiindu-i rezervate toate drepturile obtinute in
          acest sens in mod direct sau indirect (prin licente de utilizare
          si/sau publicare). Aceste elemente sunt protejate de legislatia romana
          si de textele internationale referitoare la respectarea drepturilor de
          autor, a marcilor, a desenelor si a modelelor, a brevetelor etc.{" "}
          <br />
          Orice reproducere sau reprezentare chiar si partiala prin orice
          mijloace, realizata fara acordul scris, prealabil si expres al TASWEB
          SRL sau al partenerilor sai unde acestia sunt titulari de drepturi,
          este interzisa si ilicita. Nerespectarea acestei interdictii
          constituie o contrafacere sau un act de concurenta neloiala care
          angajeaza responsabilitatea civila si penala a autorului sau. <br />
          Punerea in aplicare a hyperlink-urilor catre orice pagina a acestui
          Site Site necesita acordul scris, prealabil si expres al TASWEB SRL.
          Utilizarea oricaror informatii de pe Site in alte scopuri decat cel
          consultativ, in special in scopuri publice, comerciale sau umoristice,
          poate face obiectul unor urmariri penale atat in Romania, cat si in
          strainatate. <br />
          <br />
          17. DISPOZITII FINALE
          <br />
          <br />
          TASWEB SRL prelucrează datele dumneavoastră cu caracter personal
          conform Politicilor privind Protecția si prelucrarea datelor cu
          caracter personal . <br />
        </Typography>
        <Box marginY="20px">
          <Typography variant="h5" textAlign="center">
            POLITICA PRIVIND PROTECTIA SI PRELUCRAREA DATELOR CU CARACTER
            PERSONAL
          </Typography>
          <Typography>
            Informatiile pe care vi le oferim mai jos ofera o privire de
            ansamblu asupra abordarii noastre in ceea ce priveste prelucrarea
            datelor dumneavoastra cu caracter personal si a drepturilor
            dumneavoastra conform prevederilor legislatiei privind protectia
            datelor. <br />
            <br />
            Datele operatorului
            <br />
            <br />
            TASWEB SRL cu sediul social in Cluj-Napoca, str. Mehedinti nr. 25 ,
            ap. 6 județul Cluj, România, înregistrată la Registrul Comerțului
            sub nr. J12/3708/2023, CUI 48726629, denumit in cele ce urmează
            “TASWEB SRL”, (operator de date cu caracter personal). <br />
            <br />
            Email: data protection@
            <br />
            <br />
            1. Categorii de date cu caracter personal
            <br />
            <br />
            Pentru scopurile Prelucrarii mentionate la punctul II de mai jos,
            prelucram datele cu caracter personal ale dumneavoastra furnizate In
            cadrul site-ului nostru - denumite in continuare „Date cu caracter
            personal”, dupa cum urmeaza: <br />
            • Nume, prenume, adresa de e-mail, adresa de domiciliu, contul
            bancar, (denumite în continuare „Date personale”); <br />
            • Numarul comenzii plasate, tipul produselor comandate, informatii
            pentru plata, detalii tranzactii (denumite în continuare „Date
            referitoare la comandă”); <br />
            • Continutul comentariului transmis si subiectul acestuia,
            continutul documentelor atasate si transmise catre TASWEB SRL,
            informatii transmise de clienti in cardul sondajelor (denumite in
            continuare „Date de comunicare”); <br />
            • Informatii privind utilizarea contului de utilizator si a
            Site-ului de catre dumneavoastra, cum ar fi data si ora la care ati
            accesat Webite-ul, inclusiv adresa IP publica, Device ID public si
            date similare despre dumneavoastra (denumite in continuare „Date de
            monitorizare”). <br />
            TASWEB SRL colecteaza Datele cu caracter personal in următoarele
            modalitati: direct de la dumneavoastra (furnizate pe site, e-mail,
            retele de socializare ori prin alte mijloace de comunicare), din
            rapoartele traficului inregistrat de serverele care gazduiesc
            site-ul, din retele sociale precum si prin intermediul cookie-urilor
            sau de la parteneri terti care ne comunica date in vederea livrarii
            produselor catre dvs. (de ex. platforme de vanzare online). <br />
            <br />
            2. Scopurile Prelucrarii
            <br />
            <br />
            Prelucram Datele dumneavoastra cu caracter personal in masura
            permisa sau impusa de legislatia aplicabila in urmatoarele scopuri:{" "}
            <br />
            1. pentru furnizarea bunurilor si serviciilor oferite de catre
            TASWEB SRL, cuprinzand actiunile specifice acestei activitati,
            inclusiv pentru a permite folosirea cat mai optima a Site-ului si in
            scopul obtinerii de informatii statistice pentru Imbunatatirea
            serviciilor TASWEB SRL (denumite in continuare „Scopuri legate de
            furnizarea serviciilor si functionarea Site-ului”); .<br />
            2. pentru administrarea sigura a sistemului informatic al TASWEB SRL
            si in scopul evitarii activitatilor infractionale (denumite in
            continuare „Scopuri legate de securitate si prevenirea fraudei”);{" "}
            <br />
            3. pentru a permite clientilor sa creeze un cont pe Site-ul nostru,
            sa plaseze comenzi online si sa cumpere produse din magazinul TASWEB
            SRL. Utilizam informatiile pe care ni le furnizati pentru a
            administra contul creat pe Site-ul nostru. Puteti sa va administrati
            contul, sa profitati de oferte speciale, sa faceti mai usor
            cumparaturi si sa va administrati setarile personale (denumite in
            continuare „Scopuri legate de supermarket online”); <br />
            4. pentru a permite clientilor sa ofere sugestii si sesizari cu
            privire la serviciile oferite de TASWEB SRL (denumite In continuare
            „Scopuri legate de sugestii si sesizari ale clientilor”); <br />
            5. pentru a permite furnizorilor sa ofere opiniile si comentariile
            lor in legatura cu activitatea TASWEB SRL T sau sa propuna idei de
            colaborare sau sa transmita oferte catre TASWEB SRL (denumite in
            continuare „Scopuri legate de furnizori”); <br />
            6. pentru a permite contactarea si administrarea contractarii
            potentialilor parteneri TASWEB SRL (denumite in continuare „Scopuri
            legate de parteneri expansiune”).; <br />
            7. pentru a permite realizarea activitatilor de marketing (denumite
            in continuare „Scopuri legate de marketing”); <br />
            Vom utiliza Datele cu caracter personal, astfel cum vor fi furnizate
            de dumneavoastra catre TASWEB SRL, in scopurile mentionate in
            aceasta Politica. <br />
            <br />
            3.Temeiul Prelucrarii Datelor dumneavoastra cu caracter personal
            <br />
            <br />
            In general, prelucrarea Datelor cu caracter personal este necesara
            in vederea desfasurarii activitatii specifice scopurilor
            Prelucrarii. <br />
            In general, vi se solicita sa furnizati Datele dumneavoastra cu
            caracter personal in mod obligatoriu, cu exceptia situatiilor
            limitate in care va comunicam ca anumite informatii sunt
            facultative. Daca nu ne oferiti respectivele date cerute a fi
            furnizate in mod obligatoriu, procesul poate fi intarziat sau chiar
            imposibil. Va rugam sa tineti cont ca, in cazul Programului de
            loialitate, daca nu ne furnizati datele cu caracter personal, sau nu
            va dati consimtamantul pentru profilare, nu va putem propune oferte
            sau beneficii personalizate. <br />
            Pentru a putea prelucra Datele dumneavoastra cu caracter personal,
            TASWEB SRL se bazeaza pe diferite temeiuri juridice, dupa cum
            urmeaza: <br />
            1.Consimtamant: <br />
            a)Ne putem baza pe acordul dumneavoastra de a va utiliza
            informatiile personale in anumite scopuri de marketing direct,
            consimtamantul fiind temeiul juridic pentru colectarea, prelucrarea
            si utilizarea Datelor dumneavoastra cu caracter personal, astfel cum
            este permis de legea aplicabila. Va puteti retrage consimtamantul in
            orice moment, contactandu-ne la adresele de email:
            taswebcluj@gmail.com
            <br />
            b) Totodata, pe baza consimtamantului dvs., tinem evidenta
            istoricului dvs. de cumparaturi, pentru a putea afisa pe site, dupa
            momentul logarii de catre dvs. in cont, acest istoric. <br />
            2.Executarea unui contract sau realizarea demersurilor in vederea
            incheierii unui contract: <br />
            Prelucrarea este necesara pentru executarea contractului la care
            persoana vizata este parte sau pentru a face demersurile necesare
            incheierii contractului dintre dumneavoastra si TASWEB SRL. <br />
            De exemplu, daca utilizati serviciile noastre pentru a face o
            comanda online, va vom utiliza Datele cu caracter personal pentru a
            ne indeplini obligatia de a finaliza si a administra acea comanda in
            conformitate cu contractul pe care il avem cu dumneavoastra. De
            asemenea, Prelucrarea Datelor dumneavoastra cu caracter personal
            poate fi necesara pentru a face demersuri inainte de incheierea unui
            posibil contract, in conditiile in care sunteti un potential
            partener si doriti sa ne contactati in vederea unei eventuale
            colaborari comerciale; <br />
            3.Interese legitime: <br />
            Putem folosi informatiile pentru interesele noastre legitime, cum ar
            fi oferirea celui mai adecvat continut al Site-ului, marketing,
            pentru a ne imbunatati si promova produsele si serviciile, precum si
            continutul pe Site-ul nostru, si in scopuri administrative si de
            detectare a fraudei. <br />
            4.Obligatii legale: <br />
            Prelucrarea datelor este necesara pentru indeplinirea obligatiilor
            noastre legale, cum ar fi obligatii prevazute de legislatia
            contabila si fiscala sau solicitari ale autoritatilor publice.{" "}
            <br />
            <br />
            4. Transferuri de date, destinatari si temeiul legal al acestor
            transferuri
            <br />
            <br />
            4.1.Destinatari
            <br />
            TASWEB SRL va pot transfera datele unor agentii guvernamentale si de
            reglementare, instante si alte autoritati guvernamentale, conform
            prevederilor legislatiei aplicabile, in baza Art. 6(1) (c) GDPR, si
            consultantilor externi cu rol de operatori de date (de exemplu,
            avocati, contabili, auditori etc.) in baza Art. 6 (1) (f) GDPR.{" "}
            <br />
            Prestatori de servicii : in cadrul operatiunilor sale comerciale
            normale, TASWEB SRL incheie contracte cu prestatori de servicii, in
            vederea desfasurarii anumitor sarcini legate de administrarea
            clientelei sau IT, de gestionarea furnizorilor, care pot prelucra
            date cu caracter personal, inclusiv pentru: procesarea platilor (de
            ex. banci, procesatori de plati etc.), livrarea produselor (de ex.
            transportatori/curieri), Prestatorii terti de servicii au semnat
            clauze de confidentialitate si nu li se permite sa utilizeze Datele
            dumneavoastra cu caracter personal in alte scopuri decat cele
            mentionate in prezenta Politica de Confidentialitate. <br />
            Analiza istoricului dvs. de cumparaturi si profilarea sunt efectuate
            prin intermediul tertilor. Datele prelucrate de catre acesti terti
            pentru indeplinirea rolului alocat acestora: segment client, cod
            postal, profil de opt-in, tipul clientului, date de identificare
            client (nume, adresa, data ultimei logari, data ultimei
            cumparaturi,). <br />
            4.2. Transferuri de date transfrontaliere
            <br />
            Transferam Datele dumneavoastra cu caracter personal in afara tarii
            in care va aflati, in tari aflate pe teritoriul Uniunii Europene.
            Aceste transferuri au loc conform Clauzelor Standard CE asumate de
            catre parti. <br />
            <br />
            5. Perioade de retentie
            <br />
            <br />
            Datele cu caracter personal prelucrate in scopurile mentionate in
            prezenta vor fi pastrate doar cat este necesar, pe durata existentei
            contului dumneavoastra si ulterior, in timpul unei perioade de
            tranzitie (de exemplu, pentru primirea unui raspuns la solicitarea
            dumneavoastra, pentru livrarea bunurilor, emiterea facturilor) sau
            pentru oricare alta perioada mai indelungata in care TASWEB SRL are
            obligatia legala de a arhiva Datele cu caracter personal. <br />
            Daca se initiaza o actiune judiciara, Datele cu caracter personal
            pot fi pastrate pana la finalul acestei actiuni, inclusiv
            eventualele perioade de recurs, iar apoi vor fi sterse sau arhivate,
            conform prevederilor legislatiei aplicabile. <br />
            In principiu, va vom pastra Datele cu caracter personal pe durata
            solicitata sau permisa de legislatia aplicabila. Ulterior, vom
            elimina/sterge Datele dumneavoastra cu caracter personal din
            sistemele si evidentele noastre si/sau vom lua masuri pentru a le
            anonimiza, astfel incat sa nu mai puteti fi identificat in baza
            acestora. In toate cazurile, Datele cu caracter personal din
            contractele, comunicarile si alte documente identificate pot fi
            supuse unor cerinte legale de pastrare, care necesita, in principiu,
            pastrarea de pana la 10 ani. Orice alte Date cu caracter personal
            vor fi sterse, in principiu, in 6 luni de la ştergerea contului
            dumneavoastra de utilizator sau de la colectarea lor prin
            intermediul Site-ului, cu exceptia Datelor Referitoare la Comanda
            care vor putea fi pastrate pana la 3 ani de la data tranzactiei sau,
            dupa caz, pentru o perioada necesara pentru indeplinirea unor
            obligatii legale (inclusiv daca suntem obligati astfel printr-o
            hotarare judecatoreasca) sau daca este necesar pentru motive de
            securitate, prevenirea fraudei, litigii sau abuzuri. <br />
            <br />
            6. Proceduri de securitate
            <br />
            <br />
            In conformitate cu legislatia europeana pentru protectia datelor,
            folosim proceduri rezonabile pentru a preveni accesul neautorizat si
            folosirea neadecvata a Datelor cu caracter personal. <br />
            Utilizam sisteme si proceduri adecvate pentru a proteja şi securiza
            Datele cu caracter personal pe care ni le transmiteti. De asemenea,
            folosim proceduri de securitate si restrictionari tehnice si fizice
            ale accesarii si folosirii Datelor cu caracter personal pe serverele
            noastre. Numai personalul autorizat poate accesa datele personale in
            timp ce lucreaza. <br />
            <br />
            7. Drepturile dumneavoastra
            <br />
            <br />
            Daca v-ati declarat consimtamantul cu privire la anumite activitati
            de Prelucrare, puteti retrage acest consimtamant in orice moment cu
            efecte viitoare. O astfel de retragere nu va afecta legalitatea
            Prelucrarii anterior retragerii consimtamantului. <br />
            In baza conditiilor prevazute de legislatia aplicabila (GDPR), aveti
            urmatoarele drepturi: <br />
            1. Dreptul de acces: Aveti dreptul de a vi se comunica, la cerere,
            daca Datele dumneavoastra cu caracter personal sunt prelucrate, si
            daca da, aveti dreptul de a solicita accesarea acestora.
            informatiile includ, printre altele, scopurile Prelucrarii,
            categoriile de date cu caracter personal afectate si destinatarii
            sau categoriile de destinatari carora le-au fost divulgate sau le
            vor fi divulgate Datele dumneavoastra cu caracter personal. Aveti
            dreptul de a obtine o copie a Datelor cu caracter personal
            prelucrate. Pentru copii suplimentare, va putem percepe o taxa
            rezonabila, in baza costurilor administrative. <br />
            2. Dreptul la rectificare: 2.Aveti dreptul de a obtine din partea
            noastra rectificarea Datelor dumneavoastra cu caracter personal
            incorecte. in functie de scopurile Prelucrarii, aveti dreptul de a
            completa Datele cu caracter personal incomplete, inclusiv prin
            intermediul unei declaratii suplimentare. <br />
            3. Dreptul la stergere („dreptul de a fi uitat”): Aveti dreptul de a
            ne solicita sa va stergem Datele cu caracter personal, prin
            transmiterea unui email la adresa taswebcluj@gmail.com
            <br />
            4. Dreptul la restrictionare: Aveti dreptul de a solicita
            restrictionarea Prelucrarii Datelor dumneavoastra cu caracter
            personal. in acest caz, datele respective vor fi marcate si pot fi
            prelucrate de noi doar in anumite scopuri. <br />
            5. Dreptul la portabilitatea datelor: Aveti dreptul de a primi
            Datele dumneavoastra cu caracter personal pe care ni le-ati
            furnizat, in format structurat, comun si care poate fi citit de
            aparate, si aveti dreptul de a transmite aceste date unei alte
            entitati fara obiectii din partea noastra. <br />
            6. Dreptul de a obiecta: Aveti dreptul de a obiecta, pe motive
            legate de situatia dumneavoastra, in orice moment, fata de
            Prelucrarea Datelor dumneavoastra cu caracter personal de catre noi,
            si ni se poate solicita sa nu va mai prelucram Datele cu caracter
            personal. Daca aveti dreptul la obiectie si vi-l exercitati, nu vom
            mai prelucra Datele dumneavoastra cu caracter personal in scopul
            respectiv. Exercitarea acestui drept nu presupune niciun cost. Acest
            drept poate fi invalidat in special daca Prelucrarea Datelor
            dumneavoastra cu caracter personal este necesara pentru
            formalitatile aferente incheierii unui contract sau pentru
            indeplinirea unui contract deja incheiat. <br />
            Drepturile mentionate mai sus se vor exercita prin formularea unei
            cereri in forma scrisa, datata si semnata, inaintata catre TASWEB
            SRL la adresa postala Cluj-Napoca, strada Mehedinti nr 25, ap6, in
            atentia Responsabilului cu protectia datelor – sau la adresa de
            email taswebcluj@gmail.com
            <br />
            In cerere, puteti arata daca doriti ca informatiile sa fie
            comunicate la o anumita adresa, care poate fi şi de posta
            electronica, sau printr-un serviciu de corespondenta care sa asigure
            ca predarea vi se va face numai personal. Va rugam sa tineti cont
            ca, inainte de a da curs oricarei astfel de cereri, ne rezervam
            dreptul de a va verifica identitatea, pentru a ne asigura ca
            solicitarea provine chiar din partea dvs. <br />
            TASWEB SRL va comunica informatiile solicitate, in termen de 30 de
            zile de la data primirii cererii, cu respectarea solicitarii
            exprimate de dumneavoastra privind modul de comunicare. <br />
            Va rugam sa luati in considerare faptul ca drepturile de mai sus pot
            fi limitate in baza legislatiei nationale aplicabile privind
            protectia datelor TASWEB SRL, in calitate de operator de date,
            ramane punctul de contact central in vederea exercitarii acestor
            drepturi. <br />
            De asemenea, aveti dreptul de a formula o plangere la Autoritatea
            Nationala de Supraveghere a Prelucrarii Datelor cu Caracter
            Personal. <br />
            <br />
            8. Cum prelucram datele personale ale copiilor? <br />
            <br />
            Serviciile oferite de Site-ul www.printworks.ro nu sunt destinate
            minorilor.
          </Typography>
        </Box>
      </Grid>
    </Modal>
  );
};
