import React, { useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";

import { useSelector } from "react-redux";
import {
  selectInviteValues,
  selectTextColorValue,
  selectTextValues,
} from "./weddingSlice";

export const WeddingInvite = (props) => {
  const texts = useSelector(selectTextValues);
  const invite = useSelector(selectInviteValues);
  const textColor = useSelector(selectTextColorValue);
  const folioType = useSelector(selectInviteValues);
  const [inviteImage, setInviteImage] = useState(invite.image);
  const checkTextColor = () => {
    if (folioType?.folioType === "None") {
      return textColor;
    } else if (folioType?.folioType === "Gold") {
      return "#D4AE67";
    } else if (folioType?.folioType === "Silver") {
      return "#BEBFC1";
    } else {
      return "#CF8F63";
    }
  };
  const matches = useMediaQuery("(min-width:1100px)");
  useEffect(() => {
    if (!invite.isUploaded) {
      if (folioType?.folioType === "None") {
        setInviteImage(invite.previewImages.invitatieSimpla);
      } else if (folioType?.folioType === "Gold") {
        setInviteImage(invite.previewImages.invitatieGold);
      } else if (folioType?.folioType === "Silver") {
        setInviteImage(invite.previewImages.invitatieSilver);
      } else {
        setInviteImage(invite.previewImages.invitatieBronze);
      }
    }
  }, [invite.folioType]);

  const currentTextColor = checkTextColor();
  return (
    <Grid
      item
      xs={10}
      container
      sx={{
        height: matches ? "70vh" : "40vh",
        backgroundColor: "#F6F6F6",
      }}
      justifyContent="center"
    >
      <div
        id="divToPrint"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {inviteImage && (
          <img
            src={inviteImage}
            alt="weddingImage"
            style={{
              height: matches ? "calc(24vh + 24vw)" : "39.8vh",
            }}
          ></img>
        )}
        {!invite.isUploaded &&
          invite.texts?.map((text) => (
            <Typography
              style={{
                flexGrow: 1,
                color: text.isFolio ? currentTextColor : textColor,
                top: text.top,
                ...(!text.center && { left: text.left }),
                position: "absolute",
                // fontSize: matches ? text.fontSize : text.mobileFontSize,
                fontSize: text.fontSize,
                fontFamily: text.fontFamily,
                textTransform: text.upperCaseOnly && "uppercase",
                letterSpacing: text.letterSpacing,
                paddingLeft: text.letterSpacing,
                fontWeight: text.fontWeight && text.fontWeight,
              }}
            >
              {texts?.[text.id]}
            </Typography>
          ))}
      </div>
    </Grid>
  );
};
