import {
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../..";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postNewAddress,
  selectLoadingAddressesState,
} from "../userAddressesSlice";
import { LoadingBackdrop } from "../../Components/LoadingBackdrop";
import { isEmpty } from "./validatation";
import {
  changeAddressValue,
  changeBillingValue,
} from "../../Sticker/createStickerSlice";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

export const NewAddressModal = (props) => {
  const { open, handleClose, billing } = props;
  const [billingType, setBillingType] = useState("personal");
  const loadingState = useSelector(selectLoadingAddressesState);
  const { t } = useTranslation();
  const [billingAddress, setBillingAddress] = useState({
    name: "",
    phoneNo: "",
    lastName: "",
    county: "",
    city: "",
    street: "",
    streetNo: "",
    stairNumber: "",
    floor: "",
    appartmentNo: "",
    companyName: "",
    bank: "",
    vatCode: "",
    bankAccount: "",
    taxPayer: "",
    billing: billing,
  });

  const dispatch = useDispatch();

  const handleBillingAddressChange = (field, value) => {
    const newValue = { [field]: value };
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      ...newValue,
    }));
  };

  const handleChangeBillingType = (value) => {
    const newValue = { userType: value !== "personal" };
    setBillingAddress((billingAddress) => ({
      ...billingAddress,
      ...newValue,
    }));
    setBillingType(value);
  };

  const addNewAddress = async () => {
    await dispatch(postNewAddress({ address: billingAddress })).then((res) => {
      if (!billing) {
        dispatch(changeAddressValue(res.payload[res.payload.length - 1]));
      } else {
        dispatch(changeBillingValue(res.payload[res.payload.length - 1]));
      }
    });

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableEnforceFocus
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        border: "0px",
      }}
    >
      <Grid
        container
        item
        justifyContent="center"
        xs={11}
        md={6}
        padding="24px"
        sx={{
          background: colors.secondary,
          borderRadius: "12px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <LoadingBackdrop isLoading={loadingState.postNewAddress} />

        <Grid item container xs={12} paddingBottom="12px">
          <Typography variant="h5">
            {billing ? t("new.billing") : t("new.address")}
          </Typography>
        </Grid>
        {billing && (
          <Grid item container xs={12} justifyContent="space-evenly">
            <Button
              onClick={() => handleChangeBillingType("personal")}
              sx={{
                width: "45%",
                border: "1px solid black",
                borderBottom: "0",
                background:
                  billingType === "personal" ? colors.customYellow : "none",
              }}
            >
              {t("personal")}
            </Button>
            <Button
              onClick={() => handleChangeBillingType("company")}
              sx={{
                width: "45%",
                border: "1px solid black",
                borderBottom: "0",
                background:
                  billingType === "company" ? colors.customYellow : "none",
              }}
            >
              {t("header.company")}
            </Button>
          </Grid>
        )}
        <Grid item container xs={12} justifyContent="center">
          <Divider
            flexItem
            orientation="horizontal"
            sx={{
              borderRightWidth: 1,
              background: colors.secondary,
              width: "100%",
            }}
          />
        </Grid>

        <Grid
          style={{
            maxHeight: "50vh",
            overflow: "auto",
            marginTop: "12px",
          }}
          item
          container
          spacing={3}
        >
          <Grid item container xs={12} spacing={3}>
            <Grid item container xs={12}>
              <Typography fontWeight="bold">{t("contact.name")}</Typography>
            </Grid>
            <Grid item container xs={6} md={4} justifyContent="center">
              <StyledTextField
                fullWidth
                required
                error={isEmpty(billingAddress?.name)}
                value={billingAddress?.name}
                onChange={(event) =>
                  handleBillingAddressChange("name", event.target.value)
                }
                size="small"
                label={t("first.name")}
              />
            </Grid>
            <Grid item container xs={6} md={4} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                error={isEmpty(billingAddress?.lastName)}
                value={billingAddress?.lastName}
                onChange={(event) =>
                  handleBillingAddressChange("lastName", event.target.value)
                }
                label={t("last.name")}
                required
              />
            </Grid>
            <Grid item container xs={12} md={4} justifyContent="center">
              <StyledTextField
                size="small"
                fullWidth
                error={isEmpty(billingAddress?.phoneNo)}
                value={billingAddress?.phoneNo}
                onChange={(event) =>
                  handleBillingAddressChange("phoneNo", event.target.value)
                }
                label={t("contact.phone")}
                required
              />
            </Grid>
          </Grid>
          {billing && billingType === "company" && (
            <Grid item container xs={12} spacing={3}>
              <Grid item container xs={12}>
                <Typography fontWeight="bold">{t("company.data")}</Typography>
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <StyledTextField
                  fullWidth
                  required
                  value={billingAddress?.companyName}
                  error={isEmpty(billingAddress?.companyName)}
                  onChange={(event) =>
                    handleBillingAddressChange(
                      "companyName",
                      event.target.value
                    )
                  }
                  size="small"
                  label={t("contact.name")}
                />
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <StyledTextField
                  fullWidth
                  required
                  error={isEmpty(billingAddress?.vatCode)}
                  value={billingAddress?.vatCode}
                  onChange={(event) =>
                    handleBillingAddressChange("vatCode", event.target.value)
                  }
                  size="small"
                  label={t("company.cui")}
                />
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <StyledTextField
                  fullWidth
                  required
                  size="small"
                  label={t("company.nr.comert")}
                />
              </Grid>
              <Grid item container xs={6} justifyContent="center">
                <StyledTextField
                  fullWidth
                  required
                  size="small"
                  value={billingAddress?.bank}
                  onChange={(event) =>
                    handleBillingAddressChange("bank", event.target.value)
                  }
                  label={t("company.bank")}
                />
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <StyledTextField
                  fullWidth
                  required
                  size="small"
                  value={billingAddress?.bankAccount}
                  onChange={(event) =>
                    handleBillingAddressChange(
                      "bankAccount",
                      event.target.value
                    )
                  }
                  label={t("company.iban")}
                />
              </Grid>
            </Grid>
          )}
          <Grid item container xs={12} spacing={3}>
            <Grid item container xs={12}>
              <Typography fontWeight="bold">
                {billing ? t("billing.data") : t("contact.address")}
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                required
                fullWidth
                size="small"
                error={isEmpty(billingAddress?.county)}
                value={billingAddress?.county}
                onChange={(event) =>
                  handleBillingAddressChange("county", event.target.value)
                }
                label={t("county")}
              />
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                size="small"
                fullWidth
                error={isEmpty(billingAddress?.city)}
                value={billingAddress?.city}
                onChange={(event) =>
                  handleBillingAddressChange("city", event.target.value)
                }
                label={t("city")}
                required
              />
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                error={isEmpty(billingAddress?.street)}
                value={billingAddress?.street}
                onChange={(event) =>
                  handleBillingAddressChange("street", event.target.value)
                }
                label={t("street.name")}
                required
              />
            </Grid>

            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                error={isEmpty(billingAddress?.streetNo)}
                value={billingAddress?.streetNo}
                onChange={(event) =>
                  handleBillingAddressChange("streetNo", event.target.value)
                }
                label={t("building.number")}
              />
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                value={billingAddress?.stairNumber}
                onChange={(event) =>
                  handleBillingAddressChange("stairNumber", event.target.value)
                }
                label={t("stair.number")}
              />
            </Grid>

            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                value={billingAddress?.floor}
                onChange={(event) =>
                  handleBillingAddressChange("floor", event.target.value)
                }
                label={t("floor")}
              />
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <StyledTextField
                fullWidth
                size="small"
                value={billingAddress?.appartmentNo}
                onChange={(event) =>
                  handleBillingAddressChange("appartmentNo", event.target.value)
                }
                label={t("appartament")}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item container xs={12}>
              <Typography>* {t("asterix.required")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <Divider
            flexItem
            orientation="horizontal"
            sx={{
              borderRightWidth: 1,
              background: colors.secondary,
              width: "100%",
              marginTop: "12px",
            }}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end" marginTop="12px">
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleClose}
            sx={{ marginRight: "12px" }}
          >
            {t("cancel.button")}
          </Button>
          <Button
            size="small"
            color="success"
            disabled={
              billingAddress.name === "" ||
              billingAddress?.street === "" ||
              billingAddress?.streetNo === "" ||
              billingAddress?.city === "" ||
              billingAddress?.county === "" ||
              billingAddress?.phoneNo === "" ||
              billingAddress?.lastName === "" ||
              (billingType === "company" &&
                (billingAddress?.vatCode === "" ||
                  billingAddress.companyName === ""))
            }
            variant="contained"
            onClick={addNewAddress}
          >
            {billing ? t("use.billing.address") : t("use.address")}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
