import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { WeddingStepper } from "./components/WeddingStepper";
import { WeddingCustomizeStep } from "./components/WeddingCustomiseStep";
import { WeddingDetailsStep } from "./components/WeddingDetailsStep";
import { WeddingSummaryStep } from "./components/WeddingSummaryStep";
import { WeddingComponent } from "./components/WeddingComponent";
import { useSelector } from "react-redux";
import { selectInviteValues } from "./components/weddingSlice";
import { ACCESS_TOKEN } from "../Auth/constants";
import { useNavigate } from "react-router-dom";
export const WeddingSteps = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const invite = useSelector(selectInviteValues);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
      navigate("/login", { state: { route: "/customizeWedding" } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid item container height={{ xs: "auto", md: "100vh" }}>
      <WeddingStepper activeStep={activeStep} setActiveStep={setActiveStep} />

      <Grid
        item
        container
        xs={12}
        justifyContent={{ xs: "center", md: "unset" }}
      >
        <Grid item container xs={10} md={6} marginTop="3vh">
          <WeddingComponent />
        </Grid>
        {invite.isUploaded ? (
          <Grid item container xs={12} md={6}>
            {activeStep === 0 && <WeddingCustomizeStep />}

            {activeStep === 1 && <WeddingSummaryStep />}
          </Grid>
        ) : (
          <Grid item container xs={12} md={6}>
            {activeStep === 0 && <WeddingDetailsStep />}
            {activeStep === 1 && <WeddingCustomizeStep />}

            {activeStep === 2 && <WeddingSummaryStep />}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
