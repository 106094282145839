import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveWeddingToBackend } from "../../../api";
import {
  displayToastError,
  displayToastSuccess,
} from "../../../utils/ErrorBoudary/errorBoudarySlice";

export const sendWeddingInviteOrder = createAsyncThunk(
  "order/sendWeddingOrder",
  async (
    {
      stickers,
      weddingDetails,
      totalPrice,
      userInformation,
      userBilling,
      hasCourier,
    },
    thunkAPI
  ) => {
    const response = await saveWeddingToBackend(
      stickers,
      weddingDetails,
      totalPrice,
      userInformation,
      userBilling,
      hasCourier
    );
    if (response.errorText.length === 0) {
      thunkAPI.dispatch(displayToastSuccess("Send order succsesful"));
      return response;
    } else {
      thunkAPI.dispatch(displayToastError(response.errorMessage));
      thunkAPI.rejectWithValue("Cannot send order");
    }
  }
);

const initialState = {
  fileToSend: "",
  texts: {},
  invite: {
    image: null,
    texts: {},
    folioType: "Gold",
    isUploaded: false,
  },
  additionalAccesories: {
    envelope: "none",
    paperType: "simple",
    seal: "none",
  },
  textColor: "#000000",
  quantity: 1,
  pricePerInvite: {
    folio: 0,
    cardboard: 0,
    softouch: 0,
  },
  inviteTotalPrice: 0,
};

export const weddingSliceReducer = createSlice({
  name: "wedding",
  initialState: {
    formData: {
      fileToSend: "",
      texts: {},
      invite: {
        image: null,
        texts: {},
        folioType: "Gold",
        isUploaded: false,
      },
      additionalAccesories: {
        envelope: "none",
        paperType: "simple",
        seal: "none",
      },
      textColor: "#000000",
      quantity: 1,
      pricePerInvite: {
        folio: 0,
        cardboard: 0,
        softouch: 0,
      },
      inviteTotalPrice: 0,
    },
  },
  reducers: {
    changeTextValue: (state, action) => {
      state.formData.texts[action.payload.key] = action.payload.value;
    },
    changeFileToSendValue: (state, action) => {
      state.formData.fileToSend = action.payload;
    },
    changeInviteValue: (state, action) => {
      state.formData.invite[action.payload.key] = action.payload.value;
    },
    clearTextsValue: (state, action) => {
      state.formData.texts = {};
    },
    changeAdditionalsValue: (state, action) => {
      state.formData.additionalAccesories[action.payload.key] =
        action.payload.value;
    },
    changeTextColorValue: (state, action) => {
      state.formData.textColor = action.payload;
    },
    changeQuantityValue: (state, action) => {
      state.formData.quantity = action.payload;
    },
    changeInvitePriceValue: (state, action) => {
      state.formData.pricePerInvite[action.payload.key] = parseFloat(
        action.payload.value
      );
      state.formData.inviteTotalPrice =
        state.formData.pricePerInvite["folio"] +
        state.formData.pricePerInvite["cardboard"] +
        state.formData.pricePerInvite["softouch"];
    },
    resetWeddingState: (state, action) => {
      state.formData = initialState;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  changeTextValue,
  changeInviteValue,
  changeTextColorValue,
  changeQuantityValue,
  changeAdditionalsValue,
  clearTextsValue,
  changeFileToSendValue,
  changeInvitePriceValue,
  resetWeddingState,
} = weddingSliceReducer.actions;
export const selectTextValues = (state) => state.wedding.formData.texts;
export const selectInviteValues = (state) => state.wedding.formData.invite;
export const selectTextColorValue = (state) => state.wedding.formData.textColor;
export const selectFileToSendValue = (state) =>
  state.wedding.formData.fileToSend;

export const selectAdditionalsValue = (state) =>
  state.wedding.formData.additionalAccesories;
export const selectInvitesQuantityValue = (state) =>
  state.wedding.formData.quantity;
export const selectTotalPriceOfAnInviteValue = (state) =>
  state.wedding.formData.inviteTotalPrice;
export const selectPricePerInviteValue = (state) =>
  state.wedding.formData.pricePerInvite;
export default weddingSliceReducer.reducer;
export const selectAllWeddingFormData = (state) => {
  return {
    texts: selectTextValues(state),
    invite: selectInviteValues(state),
    textColor: selectTextColorValue(state),
    fileToSend: selectFileToSendValue(state),
    additionalAccesories: selectAdditionalsValue(state),
    quantity: selectInvitesQuantityValue(state),
    price: selectTotalPriceOfAnInviteValue(state),
  };
};
