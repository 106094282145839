import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Gold from "../../assets/gold.jpg";
import Silver from "../../assets/silver.jpg";
import Bronze from "../../assets/bronze.jpg";
import Seal from "../../assets/seal.png";
import Pink from "../../assets/pink-env.png";
import { useSelector } from "react-redux";
import {
  selectAdditionalsValue,
  selectInvitesQuantityValue,
  selectInviteValues,
  selectTextColorValue,
} from "../weddingSlice";

export const WeddingChosenLook = () => {
  const invite = useSelector(selectInviteValues);
  const additionalValues = useSelector(selectAdditionalsValue);
  const textColor = useSelector(selectTextColorValue);
  const quantity = useSelector(selectInvitesQuantityValue);
  const [paperType, setPaperType] = useState("Simpla");

  useEffect(() => {
    console.log("xxxx", additionalValues);
    if (additionalValues.paperType === "simple") {
      setPaperType("Carton simplu");
    } else if (additionalValues.paperType === "black") {
      setPaperType("Carton Negru");
    } else if (additionalValues.paperType === "line") {
      setPaperType("Carton in linii");
    } else if (additionalValues.paperType === "bullet") {
      setPaperType("Carton in puncte");
    }
  }, [additionalValues]);
  return (
    <Grid xs={12}>
      <Grid xs={12} sx={{ padding: "15px" }}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Aspectul ales
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          Rezumatul comenzii dumneavoastră
        </Typography>
      </Grid>
      <Grid item container xs={12} sx={{ padding: "15px" }}>
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Cantitate
        </Typography>
        <Typography sx={{ padding: "5px" }}>{quantity}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{ padding: "15px" }}
        alignItems={"center"}
        gap={"10px"}
      >
        {invite.folioType !== "None" && (
          <>
            <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
              Folio
            </Typography>
            <img
              src={
                invite.folioType === "Gold"
                  ? Gold
                  : invite.folioType === "Silver"
                  ? Silver
                  : Bronze
              }
              alt="goldFolio"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                margin: "5px",
              }}
            />
          </>
        )}
        <Typography sx={{ paddingRight: "15px" }}>
          {invite.folyoType}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography sx={{ fontWeight: "bold", paddingLeft: "15px" }}>
          Tipul de carton
        </Typography>
        {/* <img
          src={Gold}
          alt="PaperType"
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            margin: "5px",
          }}
        /> */}
        <Typography sx={{ paddingRight: "15px" }}>{paperType}</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography sx={{ fontWeight: "bold", paddingLeft: "15px" }}>
          Culoare
        </Typography>
        <div
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            margin: "5px",
            backgroundColor: textColor,
          }}
        />
      </Grid>
      {/* <Grid
        item
        container
        xs={12}
        sx={{ padding: "15px" }}
        alignItems={"center"}
        gap={"10px"}
      >
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Culoarea plicului
        </Typography>
        <img
          src={Pink}
          alt="pink-env"
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            margin: "5px",
          }}
        />
        <Typography sx={{ paddingRight: "15px" }}>
          {additionalValues.envelope}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography sx={{ fontWeight: "bold", paddingLeft: "15px" }}>
          Sigiliu
        </Typography>
        <img
          src={Seal}
          alt="seal"
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            margin: "5px",
          }}
        />
        <Typography sx={{ paddingRight: "15px" }}>
          {additionalValues.seal}
        </Typography>
      </Grid> */}
    </Grid>
  );
};
