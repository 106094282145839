import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ActionCard = (props) => {
  const {
    toUrl,
    icon,
    textColor,
    coloredText,
    finalText,
    firstText,
    hoverColor,
    iconHeight,
  } = props;
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      xs={11}
      sm={5}
      md={10}
      onClick={() => navigate(toUrl)}
      sx={{ cursor: "pointer", "&:hover": { background: hoverColor } }}
      border="2px solid black"
      padding="10px"
      borderRadius="20px"
      boxShadow="2px 2px 2px black;"
      marginTop="20px"
      paddingTop="0px !important"
      marginLeft="24px"
    >
      <Grid
        item
        container
        xs={4}
        alignItems="center"
        justifyContent="center"
        height="18vh"
      >
        <img src={icon} alt="Wedding" height={iconHeight} />
      </Grid>
      <Grid item container xs={8} justifyContent="center" textAlign="center">
        <Grid
          item
          container
          maxWidth="250px"
          justifyContent="center"
          alignContent="center"
        >
          <Typography fontSize={{ xs: "16px", md: "24px" }} textAlign="left">
            {firstText} <br />
            <span
              style={{
                color: textColor,
                fontWeight: "bold",
              }}
            >
              {coloredText}
            </span>
            <br />
            {finalText}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
