import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTextValue,
  selectInviteValues,
  selectTextValues,
} from "../weddingSlice";

export const WeddingEventDetails = () => {
  const dispatch = useDispatch();
  const invite = useSelector(selectInviteValues);
  const texts = useSelector(selectTextValues);
  const findTextValue = (value) => {
    return invite.texts.filter((text) => text.id === value).length === 1;
  };
  return (
    <Grid item container xs={12} sm={11} paddingX={{ xs: "10px", sm: "0px" }}>
      <Grid
        item
        container
        xs={12}
        sm={4}
        textAlign={{ xs: "center", sm: "unset" }}
      >
        <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
          Detalii eveniment
        </Typography>
        <Typography sx={{ padding: "5px" }}>
          Completați detaliile evenimentului așa cum doriți să apară pe
          invitație
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={8}>
        {findTextValue("initialeMiri") && (
          <Grid item container xs={12} sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.initialeMiri}
              label="Initiale Miri"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dispatch(
                  changeTextValue({
                    key: "initialeMiri",
                    value: event.target.value.replace(" ", "").slice(0, 2),
                  })
                );
              }}
              placeholder="ex. D S "
              fullWidth
            />
          </Grid>
        )}
        {findTextValue("numeMiri") && (
          <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
            <TextField
              value={texts?.numeMiri}
              label="Nume Miri"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                dispatch(
                  changeTextValue({
                    key: "numeMiri",
                    value: event.target.value,
                  })
                )
              }
              placeholder="ex.Sebastian si Diana"
              fullWidth
            />
          </Grid>
        )}
        <Grid item container xs={12} justifyContent="space-between">
          {findTextValue("familieMiri") && (
            <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
              <TextField
                value={texts?.familieMiri}
                fullWidth
                label="Familie Mire"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  dispatch(
                    changeTextValue({
                      key: "familieMiri",
                      value: event.target.value,
                    })
                  )
                }
                placeholder="ex.Sebastian si Diana"
              />
            </Grid>
          )}
          {findTextValue("perechiNasi") && (
            <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
              <TextField
                value={texts?.perechiNasi}
                fullWidth
                label="Pereche Nasi"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) =>
                  dispatch(
                    changeTextValue({
                      key: "perechiNasi",
                      value: event.target.value,
                    })
                  )
                }
                placeholder="Alex si Andreea Muresan"
              />
            </Grid>
          )}
        </Grid>
        {findTextValue("dataNuntii") && (
          <Grid item xs={12} sx={{ paddingBottom: "15px" }}>
            <TextField
              fullWidth
              value={texts?.dataNuntii}
              label="Data Nuntii"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                dispatch(
                  changeTextValue({
                    key: "dataNuntii",
                    value: event.target.value,
                  })
                )
              }
              placeholder="27 iulie 1970"
            />
          </Grid>
        )}
        {/* <Grid xs={12}>
          <TextField
            label="Invite description"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Enter a description"
            multiline
            rows={3}
            sx={{ width: "350px" }}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
};
