import React from "react";
import { Grid, Typography } from "@mui/material";

import { WeddingQuantity } from "./Wedding-CustomiseLook/WeddingQuantity";
import { WeddingFolio } from "./Wedding-CustomiseLook/WeddingFolio";
import { WeddingPaperType } from "./Wedding-CustomiseLook/WeddingPaperType";
import { WeddingTextColor } from "./Wedding-CustomiseLook/WeddingTextColor";
import { WeddingEnvelopeStyles } from "./Wedding-CustomiseLook/WeddingEnvelopeStyles";
import { WeddingSeals } from "./Wedding-CustomiseLook/WeddingSeals";
import { useSelector } from "react-redux";
import {
  selectInvitesQuantityValue,
  selectInviteValues,
  selectPricePerInviteValue,
} from "./weddingSlice";

export const WeddingCustomizeStep = () => {
  const invite = useSelector(selectInviteValues);
  const invitePrice = useSelector(selectPricePerInviteValue);
  const quantity = useSelector(selectInvitesQuantityValue);
  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={{ xs: "center", md: "flex-start" }}
      marginY="5vh"
      paddingX={{ xs: "10px", md: "0px" }}
    >
      <Grid item container xs={10} justifyContent="space-between">
        <Typography
          sx={{ fontWeight: "bold", fontSize: "22px", paddingBottom: "15px" }}
        >
          Configurează aspectul
        </Typography>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "22px", paddingBottom: "15px" }}
        >
          Total:{" "}
          {(
            (invitePrice.folio + invitePrice.cardboard + invitePrice.softouch) *
            quantity
          ).toFixed(1)}{" "}
          RON
        </Typography>
      </Grid>
      <WeddingQuantity />
      <WeddingFolio />
      <WeddingPaperType />
      {!invite.isUploaded && <WeddingTextColor />}
      {/* <WeddingEnvelopeStyles /> */}
      {/* <WeddingSeals /> */}
    </Grid>
  );
};
