import React from "react";
import { Grid, Typography, Box } from "@mui/material";

export const StepComponent = (props) => {
  const { title, description, step, stepColor } = props;
  return (
    <Grid item container xs={12} marginBottom="20px">
      <Box
        sx={{
          background: stepColor,
          borderRadius: "20px",
          paddingX: "7px",
        }}
      >
        <Typography>{step}</Typography>
      </Box>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: "bold",
            marginTop: "10px",
          }}
          fontSize="20"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item container xs={12} md={10}>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
  );
};
