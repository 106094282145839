import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { colors } from "..";
import Header from "./Header";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Footer } from "./Footer";
import { routes } from "../config/routes";
import { getCurrentUser } from "../components/Auth/util/APIUtils";
import { ACCESS_TOKEN } from "../components/Auth/constants";
import { changeUserValues } from "../components/Auth/login/loginSlice";
import ErrorBoundary from "../utils/ErrorBoudary/ErrorBoudary";
import store from "../store/store";
import BackgroundImage from "../assets/background-pattern-10opacity1x.png";
import {
  getDiscounts,
  getStickerPrices,
} from "../components/Pricing/pricingSlice";
import { jsPDF } from "jspdf";
import {
  Ephesis,
  Lora,
  XanhMono,
  Roboto,
  LibreBodoni,
} from "../components/Wedding/Invites/Fonts/Fonts";

export const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (text) => {
    // eslint-disable-next-line no-restricted-globals
    const result = text === "initial" ? "" : confirm("Are you sure?");
    if (result || text === "initial") {
      localStorage.removeItem(ACCESS_TOKEN);
      store.dispatch({ type: "USER_LOGOUT" });
      dispatch(changeUserValues({ field: "user", value: {} }));
      dispatch(changeUserValues({ field: "authenticated", value: false }));
      navigate("/", { replace: true });
    }
  };
  const loadCurrentlyLoggedInUser = () => {
    getCurrentUser()
      .then((response) => {
        dispatch(changeUserValues({ field: "user", value: response }));
        dispatch(changeUserValues({ field: "authenticated", value: true }));
        if (response.status === 401) {
          handleLogout("initial");
        }
      })
      .catch((error) => {});
  };

  const addFontsToPdf = () => {
    var callAddFontEphesis = function () {
      this.addFileToVFS("Ephesis-Regular.ttf", Ephesis);
      this.addFont("Ephesis-Regular.ttf", "Ephesis", "normal");
    };
    jsPDF.API.events.push(["addFonts", callAddFontEphesis]);

    var callAddFontLora = function () {
      this.addFileToVFS("Lora-Bold.ttf", Lora);
      this.addFont("Lora-Bold.ttf", "Lora", "bold");
    };
    jsPDF.API.events.push(["addFonts", callAddFontLora]);

    var callAddFontRoboto = function () {
      this.addFileToVFS("Roboto-monospace.ttf", Roboto);
      this.addFont("Roboto-monospace.ttf", "Roboto", "monospace");
    };
    jsPDF.API.events.push(["addFonts", callAddFontRoboto]);

    var callAddFontXahn = function () {
      this.addFileToVFS("XanhMono-Regular-bold-normal.ttf", XanhMono);
      this.addFont("XanhMono-Regular-bold-normal.ttf", "Xanh Mono", "normal");
    };
    jsPDF.API.events.push(["addFonts", callAddFontXahn]);

    var callAddFonTLibre = function () {
      this.addFileToVFS("LibreBodoni.ttf", LibreBodoni);
      this.addFont("LibreBodoni.ttf", "Libre Bodoni", "normal");
    };
    jsPDF.API.events.push(["addFonts", callAddFonTLibre]);
  };
  useEffect(() => {
    loadCurrentlyLoggedInUser();
    dispatch(getStickerPrices());
    dispatch(getDiscounts());
    addFontsToPdf();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        padding: 0,
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        background: colors.main,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "cover",
      }}
    >
      <ErrorBoundary />
      {location.pathname !== "/customizeWedding" && (
        <Header handleLogout={handleLogout} />
      )}
      <Routes>
        {routes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            name={route?.title}
            element={<route.component />}
          />
        ))}
      </Routes>

      {location.pathname !== "/sticker" &&
        location.pathname !== "/customizeWedding" && location.pathname !== "/puzzle" && <Footer />}
    </Box>
  );
};
