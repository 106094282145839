import React, { Suspense } from "react";
import "./index.css";
import ReCAPTCHA from "react-google-recaptcha";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Layout } from "./containers/Layout";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { createTheme, ThemeProvider } from "@mui/material";
import store, { persistor } from "./store/store";
import "./i18n.js";
import { PersistGate } from "redux-persist/integration/react";
import DocumentMeta from "react-document-meta";
import Hotjar from "@hotjar/browser";

export const colors = {
  main: "#FFFFFF",
  secondary: "#FFFFFF",
  text: "#000000",
  ligthGray: "#eef0f2",
  customYellow: "#FFBF00",
  amber: "#FFFDEA",
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.text,
      contrastText: colors.main,
    },
    secondary: {
      main: colors.text,
      contrastText: colors.secondary,
    },
    dark: {
      main: colors.customYellow,
      contrastText: colors.text,
    },
    yellow: {
      main: colors.customYellow,
      contrastText: colors.customYellow,
    },
  },
  typography: {
    fontFamily: ["Open Sans MS", " Open Sans", "cursive"].join(","),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.text,
        },
      },
    },
    MuiButton: {
      raisedPrimary: {
        color: "white",
      },
    },
  },
});

const recapcha = (value) => {};

const root = ReactDOM.createRoot(document.getElementById("root"));

const meta = {
  title: "PrintWorks",
  description:
    "Explorează creativitatea ta cu stikere personalizate! Site-ul nostru oferă un mod unic de a-ti configura stickerele pentru a adăuga un plus de originalitate și personalitate obiectelor tale preferate",
  canonical: "https://www.printworks.ro/",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "stickere,cluj,romania,configurator",
    },
  },
};

const siteId = 3882055;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

root.render(
  <React.StrictMode>
    <ReCAPTCHA
      sitekey="6LfaSegoAAAAACbPi5l6X3uk68ruyhgpKERnRLpN"
      onChange={recapcha}
      size="invisible"
    />
    <DocumentMeta {...meta}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Suspense fallback="">
              <Router>
                <Layout />
              </Router>
            </Suspense>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </DocumentMeta>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
