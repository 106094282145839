import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Box,
  Toolbar,
  Container,
  Divider,
  Typography,
  Grid,
} from "@mui/material";

import React, { useState } from "react";
import Flag from "react-world-flags";

import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors } from "..";
import { routes } from "../config/routes";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

import { selectUserData } from "../components/Auth/login/loginSlice";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { selectStickerData } from "../components/Sticker/createStickerSlice";
import { selectCurrentPrice } from "../components/Pricing/pricingSlice";
import Logo from "../assets/PrintWorks-Logo.svg";
import MobileLogo from "../assets/MobileLogo.png";
import { selectPuzzleList } from "../components/Puzzle/utils/createPuzzleSlice";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { handleLogout } = props;
  const userState = useSelector(selectUserData);
  const puzzles = useSelector(selectPuzzleList);
  const stickerState = useSelector(selectStickerData);
  const currentPrice = useSelector(selectCurrentPrice);
  const [anchorLanguageMenu, setAnchorLanguageMenu] = useState(null);
  const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);
  const [anchorMobileMenu, setAnchorMobileMenu] = useState(null);

  const languageOpen = !!anchorLanguageMenu;
  const profileOpen = !!anchorProfileMenu;
  const mobileOpen = !!anchorMobileMenu;

  const navigate = useNavigate();

  const handleLanguageClick = (event) => {
    setAnchorLanguageMenu(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorLanguageMenu(null);
  };
  const handleMobileClick = (event) => {
    setAnchorMobileMenu(event.currentTarget);
  };
  const handleMobileClose = (event) => {
    setAnchorMobileMenu(null);
  };

  const handleProfileClick = (event) => {
    setAnchorProfileMenu(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfileMenu(null);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const showFlagDependingOnLanguage = () => {
    switch (i18n.language) {
      case "ro":
        return (
          <>
            <Grid
              item
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <Flag code="RO" height="15" />
            </Grid>
            <Typography
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                fontStyle: "normal",
                fontWeight: "bold",
              }}
            >
              {t("header.language.ro")}
            </Typography>
          </>
        );
      case "en":
        return (
          <>
            <Grid
              item
              sx={{
                display: {
                  xs: "flex",
                  md: "none",
                },
              }}
            >
              <Flag code="GB" height="15" />
            </Grid>
            <Typography
              sx={{ display: { xs: "none", md: "flex", fontWeight: "bold" } }}
            >
              {t("header.language.en")}
            </Typography>
          </>
        );
      default:
        return;
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={1}
      sx={{
        flexWrap: "wrap",
        justifyContent: "space-between",
        background: colors.secondary,
        height: "50px",
        boxShadow: "none",
        width: "100vw",
        paddingRight: "20px",
      }}
    >
      <Box
        position="absolute"
        top="10px"
        onClick={() => navigate("/")}
        left="40px"
        width={{ xs: "70px", md: "100px" }}
      >
        <img
          src={Logo}
          alt="Printworks Logo: Site configurator de stickere"
          onClick={() => navigate("/")}
          style={{ zIndex: "1000", cursor: "pointer" }}
        />
      </Box>
      <Container maxWidth={false} sx={{ paddingRight: "0px !important" }}>
        <Toolbar
          sx={{ paddingRight: "0px !important", minHeight: "50px !important" }}
        >
          <Box
            sx={{
              flexGrow: 10,
            }}
          ></Box>
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
            }}
          >
            {routes.map(
              (route) =>
                route.showInHeader && (
                  <Link
                    to={route.path}
                    key={route.title}
                    style={{ textDecoration: "none", color: colors.text }}
                  >
                    {!route.icon && (
                      <Button
                        sx={{
                          fontWeight:
                            location.pathname === route.path ? "bold" : "none",
                          borderRadius: "8px",
                        }}
                      >
                        {t(route.title)}
                      </Button>
                    )}
                  </Link>
                )
            )}
          </Box>

          {userState.authenticated && (
            <Menu
              PaperProps={{
                sx: {
                  backgroundColor: colors.secondary,
                  width: "170px",
                  display: { xs: "none", md: "flex" },
                  fontWeight: "bold",
                },
              }}
              onClose={handleProfileClose}
              onClick={handleProfileClose}
              disableAutoFocusItem
              disableScrollLock
              anchorEl={anchorProfileMenu}
              open={profileOpen}
            >
              <MenuItem
                id="en"
                style={{ minWidth: "100%", height: "30px", fontWeight: "bold" }}
                onClick={() => navigate("/history")}
              >
                {t("header.history")}
              </MenuItem>

              <MenuItem
                onClick={handleLogout}
                sx={{ color: "red", fontWeight: "bold" }}
              >
                Logout
              </MenuItem>
            </Menu>
          )}

          <Button
            variant="text"
            color="secondary"
            onClick={handleLanguageClick}
            sx={{
              height: "30px",
              textTransform: "none",
              fontWeight: "bold",
              marginLeft: !userState.authenticated ? "80px" : 0,
            }}
          >
            {showFlagDependingOnLanguage()}
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            PaperProps={{
              sx: { backgroundColor: colors.secondary, width: "170px" },
            }}
            onClose={handleLanguageClose}
            onClick={handleLanguageClose}
            disableAutoFocusItem
            disableScrollLock
            anchorEl={anchorLanguageMenu}
            open={languageOpen}
          >
            <MenuItem
              id="ro"
              style={{ minWidth: "100%", height: "30px", fontWeight: "bold" }}
              onClick={() => handleChangeLanguage("ro")}
            >
              <Flag code="RO" height="20" style={{ marginRight: "10px" }} />
              {t("header.language.ro")}
            </MenuItem>
            <MenuItem
              id="en"
              style={{ minWidth: "100%", height: "30px", fontWeight: "bold" }}
              onClick={() => handleChangeLanguage("en")}
            >
              <Flag code="GB" height="15" style={{ marginRight: "10px" }} />
              {t("header.language.en")}
            </MenuItem>
          </Menu>

          <Box sx={{ display: "flex", paddingRight: "0px !important" }}>
            {routes.map(
              (route) =>
                route.showInHeader && (
                  <Link
                    to={route.path}
                    key={route.title}
                    style={{
                      textDecoration: "none",
                      color: colors.text,
                    }}
                  >
                    {route.icon && (
                      <Button
                        sx={{
                          color: "black",
                          height: "30px",
                          marginRight: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        <ShoppingCartIcon />
                        <Typography>
                          ({stickerState.stickers?.length + puzzles?.length})
                        </Typography>
                      </Button>
                    )}
                  </Link>
                )
            )}
          </Box>

          {!userState.authenticated && (
            <Button
              sx={{
                background: colors.customYellow,
                color: "black",
                height: "30px",
                borderRadius: "16px",
                marginRight: "40px",
                fontWeight: "bold",
                textTransform: "none",
                display: { xs: "none", md: "flex" },
              }}
              onClick={() => navigate("/login")}
            >
              {t("login.button")}
            </Button>
          )}
          {userState.authenticated && (
            <Button
              variant="text"
              color="secondary"
              onClick={handleProfileClick}
              sx={{
                background: colors.customYellow,
                color: "black",
                height: "30px",
                borderRadius: "16px",
                marginRight: "40px",
                fontWeight: "bold",
                textTransform: "none",
                display: { xs: "none", md: "flex" },
              }}
            >
              {t("my.profile")}
              <KeyboardArrowDownIcon />
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              paddingRight: { xs: "0", md: "15px !important" },
            }}
          >
            <Typography fontSize="12px" fontWeight="bold">
              {parseInt(currentPrice)} RON
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <Button onClick={handleMobileClick}>
              <DensityMediumIcon sx={{ color: "black" }} />
            </Button>
            <Menu
              PaperProps={{
                sx: {
                  backgroundColor: colors.secondary,
                  fontWeight: "bold",
                  padding: "24px",
                  height: "auto",
                  width: { xs: "100vw", sm: "auto" },
                },
              }}
              onClose={handleMobileClose}
              onClick={handleMobileClose}
              disableAutoFocusItem
              disableScrollLock
              anchorEl={anchorMobileMenu}
              open={mobileOpen}
            >
              <Grid item container sx={{ height: "70px" }} alignItems="center">
                <img
                  src={MobileLogo}
                  alt="Printworks: Configurare stickere personalziate"
                  onClick={() => navigate("/")}
                  style={{ maxHeight: "70px" }}
                />
              </Grid>
              {routes.map(
                (route) =>
                  route.showInMobile &&
                  !route.icon && (
                    <MenuItem
                      component={Link}
                      to={route.path}
                      key={route.title}
                      style={{
                        textDecoration: "none",
                        color: colors.text,
                        fontSize: "24px",

                        marginBottom: "20px",
                      }}
                    >
                      {t(route.title)}
                    </MenuItem>
                  )
              )}
              <Divider />

              {userState.authenticated ? (
                <Button
                  onClick={handleLogout}
                  sx={{ color: "red" }}
                  style={{
                    background: colors.customYellow,
                    color: "black",
                    height: "30px",
                    borderRadius: "16px",
                    textDecoration: "none",
                    textTransform: "none",
                    padding: "20px",
                    fontSize: "24px",
                    boxShadow: " 2px 2px 2px black",

                    marginBottom: "20px",
                  }}
                  fullWidth
                >
                  Logout
                </Button>
              ) : (
                <Button
                  onClick={() => navigate("/login")}
                  color="dark"
                  style={{
                    background: colors.customYellow,
                    color: "black",
                    height: "30px",
                    borderRadius: "16px",
                    textDecoration: "none",
                    textTransform: "none",
                    padding: "20px",
                    fontSize: "24px",
                    boxShadow: " 2px 2px 2px black",

                    marginBottom: "20px",
                  }}
                  fullWidth
                >
                  {t("login.button")}
                </Button>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
