import Login from "../components/Auth/login/Login";
import Profile from "../components/Auth/profile/Profile";
import { CreateSticker } from "../components/Sticker/CreateSticker";
import OAuth2RedirectHandler from "../components/Auth/oauth2/OAuth2RedirectHandler";
import { Cart } from "../components/Cart/Cart";
import { OrderHistory } from "../components/OrderHistory/OrderHistory";
import { PaymentForm } from "../components/PaymentForm/PaymentForm";
import { Home } from "../components/Home/Home";
import { HomePageNew } from "../components/Home/HomePageNew";
import { SignUpNew } from "../components/Auth/signup/Signup_new";
import Faq from "../components/Faq/Faq";
import { ResetEmail } from "../components/Auth/resetPassword/ResetEmail";
import { NewPassword } from "../components/Auth/resetPassword/NewPassword";
import { EmailNotification } from "../components/Auth/resetPassword/EmailNotification";
import { TermsAndCondsPage } from "../components/PaymentForm/components/TermsAndCondsPage";
import { WeddingPage } from "../components/Wedding/WeddingPage";
import { WeddingSteps } from "../components/Wedding/WeddingSteps";
import { CreatePuzzle } from "../components/Puzzle/CreatePuzzle";

const createSticker = (props) => <CreateSticker {...props} />;
const home = (props) => <HomePageNew {...props} />;
const profile = (props) => <Profile {...props} />;
const faq = (props) => <Faq {...props} />;
const login = (props) => <Login {...props} />;
const signup = (props) => <SignUpNew {...props} />;
const oAuth2RedirectHandler = () => <OAuth2RedirectHandler />;
const cart = (props) => <Cart {...props} />;
const history = (props) => <OrderHistory {...props} />;
const paymentForm = (props) => <PaymentForm {...props} />;
const resetPassword = (props) => <ResetEmail {...props} />;
const changePassword = (props) => <NewPassword {...props} />;
const emailNotification = (props) => <EmailNotification {...props} />;
const termsAndConds = (props) => <TermsAndCondsPage {...props} />;
const weddingPage = (props) => <WeddingPage {...props} />;
const weddingStepper = (props) => <WeddingSteps {...props} />;
const createPuzzle = (props) => <CreatePuzzle {...props} />;

export const routes = [
  {
    path: "/oauth2/redirect",
    title: "header.profile",
    component: oAuth2RedirectHandler,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/login",
    title: "header.profile",
    component: login,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/wedding",
    title: "header.wedding",
    component: weddingPage,
    showInHeader: true,
    showInMobile: true,
    needAuthentication: false,
  },
  {
    path: "/sticker",
    title: "header.sticker",
    component: createSticker,
    showInHeader: true,
    showInMobile: true,
    needAuthentication: true,
  },
  {
    path: "/puzzle",
    title: "goToPuzzle.button",
    component: createPuzzle,
    showInHeader: true,
    showInMobile: true,
    needAuthentication: false,
  },
  {
    path: "/termsAndConds",

    component: termsAndConds,
    showInHeader: false,
    showInMobile: false,

    needAuthentication: false,
  },
  {
    path: "/history",
    title: "header.history",
    component: history,
    showInHeader: false,
    showInMobile: true,
    needAuthentication: true,
  },
  {
    path: "/",
    title: "header.home",
    component: home,
    showInHeader: false,
    showInMobile: false,
    needAuthentication: false,
  },

  {
    path: "/profile",
    title: "header.profile",
    component: profile,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/faq",
    title: "header.faq",
    component: faq,
    showInHeader: true,
    showInMobile: true,

    needAuthentication: false,
  },
  {
    path: "/signup",
    title: "header.profile",
    component: signup,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/cart",
    title: "header.cart",
    component: cart,
    showInHeader: true,
    showInMobile: true,
    icon: true,
    needAuthentication: true,
  },
  {
    path: "/paymentForm",
    title: "header.cart",
    component: paymentForm,
    showInHeader: false,
    needAuthentication: true,
    icon: true,
  },
  {
    path: "/resetPassword",
    component: resetPassword,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/changePassword/:token",
    component: changePassword,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/emailNotification",
    component: emailNotification,
    showInHeader: false,
    needAuthentication: false,
  },
  {
    path: "/validate",
    component: emailNotification,
    showInHeader: false,
    needAuthentication: false,
  },

  {
    path: "/customizeWedding",
    title: "header.weddingStepper",
    component: weddingStepper,
    showInHeader: false,
    showInMobile: false,
    needAuthentication: true,
  },
];

export const getUnauthenticatedRoutes = () =>
  routes.filter((route) => !route.needAuthentication);
export const getAuthenticatedRoutes = () =>
  routes.filter((route) => route.needAuthentication);
