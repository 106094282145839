import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../..";

export const InfoBox = (props) => {
  const { icon, title, description } = props;
  return (
    <Box
      height="100%"
      padding="20px"
      sx={{
        backgroundColor: colors.amber,
      }}
    >
      <Grid item container xs={12} marginBottom="10px">
        <Grid item xs={12}>
          <img src={icon} alt="Printworks" />
        </Grid>
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            marginTop: "10px",
          }}
          fontSize="20"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Typography>{description}</Typography>
      </Grid>
    </Box>
  );
};
