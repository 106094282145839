import * as React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { colors } from "..";
import Anaf1 from "../assets/anpc_1.webp";
import Anaf2 from "../assets/anpc_2.webp";
import NTPLogo from "ntp-logo-react";
import { useTranslation } from "react-i18next";
import { AcceptTermsModal } from "../components/PaymentForm/components/AcceptTermsModal";
import Logo from "../assets/PrintWorks-Logo.svg";
import { useNavigate } from "react-router-dom";

export const Footer = (props) => {
  const { t } = useTranslation();
  const [openAcceptTermsModal, setOpenAcceptTermsModal] = React.useState(false);
  const navigate = useNavigate();
  const TikTokIcon = () => {
    return (
      <svg
        focusable="false"
        viewBox="0 0 50 50"
        fill="#000000"
        width="40%"
        height="40%"
        style={{
          "&:hover": {
            backgroundColor: "red",
          },
        }}
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };
  return (
    <BottomNavigation
      showLabels
      sx={{
        background: colors.main,
        height: "auto",
        // marginTop: "100px",
        padding: "20px",
        paddingTop: "0px",
      }}
    >
      <Grid container justifyContent="center " padding="20px" spacing={3}>
        <AcceptTermsModal
          open={openAcceptTermsModal}
          handleClose={() => setOpenAcceptTermsModal(false)}
        />
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} marginBottom="20px">
            <Divider variant="middle" />
          </Grid>
          <Grid item container xs={10} alignContent="center">
            <Grid
              item
              container
              xs={12}
              md={2}
              justifyContent={{ xs: "center", md: "left" }}
              alignContent="center"
            >
              <img
                src={Logo}
                alt="Printworks Logo: Site configurator de stickere"
                style={{ width: "60px", zIndex: "1000", cursor: "pointer" }}
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={2}
              justifyContent="left"
              alignContent="center"
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  textAlign="center"
                  onClick={() => navigate("/")}
                >
                  {t("header.home")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  sx={{ color: "#000000", fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/sticker")}
                >
                  {t("header.sticker")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  sx={{ color: "#000000", fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/puzzle")}
                >
                  {t("goToPuzzle.button")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={2} alignContent="center">
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  sx={{ color: "#000000", fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/wedding")}
                >
                  {t("header.wedding")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  textAlign="center"
                  sx={{ color: "#000000", fontSize: "12px", cursor: "pointer" }}
                  onClick={() => navigate("/faq")}
                >
                  {t("header.faq")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={3} alignContent="center">
              <Grid item xs={12} justifyContent="center">
                <Typography
                  textAlign="center"
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenAcceptTermsModal(true)}
                >
                  {t("acceptTerms")}
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent="center">
                <Typography
                  textAlign="center"
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                  }}
                  onClick={() => navigate("/faq")}
                >
                  Contact: comenzi.printworks@gmail.com
                </Typography>
                <Typography
                  textAlign="center"
                  sx={{ color: "#000000", fontSize: "12px" }}
                >
                  {t("contact.phone")}: 0749210835
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={3} alignContent="center">
              <Grid item xs={12} justifyContent="center">
                <Typography
                  textAlign="center"
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  onClick={() => setOpenAcceptTermsModal(true)}
                >
                  Adresa: Cluj-Napoca, Str.Paris nr.70
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <BottomNavigationAction
              sx={{ color: "#000000" }}
              target="_blank"
              href="https://www.instagram.com/printworks_romania/"
              icon={<InstagramIcon />}
            />
            <BottomNavigationAction
              style={{
                "&:hover": {
                  backgroundColor: "blue !important",
                },
              }}
              sx={{ color: "#000000" }}
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61553002409289"
              icon={<FacebookIcon />}
            />
            <BottomNavigationAction
              target="_blank"
              sx={{ color: "#000000" }}
              href="https://www.tiktok.com/@printworksstudio"
              icon={<TikTokIcon />}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            justifyContent="center"
            marginTop="10px"
          >
            <Grid
              item
              container
              xs={12}
              md={2}
              marginRight="10px"
              justifyContent="center"
            >
              <a
                href="https://anpc.ro/ce-este-sal/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Anaf1}
                  style={{ height: "40px", width: "110px" }}
                  alt="anaf1"
                />
              </a>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              md={2}
              marginRight="10px"
            >
              <NTPLogo color="#FFFFFF" version="vertical" secret="133446" />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              md={2}
              marginRight="10px"
            >
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Anaf2}
                  style={{ height: "40px", width: "110px" }}
                  alt="anaf2"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BottomNavigation>
  );
};
