import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAdditionalsValue,
  changeInvitePriceValue,
  changeInviteValue,
  selectAdditionalsValue,
  selectInviteValues,
} from "../weddingSlice";
import { selectPricingList } from "../../../Pricing/pricingSlice";

export const WeddingPaperType = () => {
  const dispatch = useDispatch();
  const additionals = useSelector(selectAdditionalsValue);
  const priceList = useSelector(selectPricingList);
  const inviteValues = useSelector(selectInviteValues);

  useEffect(() => {
    const simpleCardboardPrice =
      priceList.length > 0 &&
      priceList?.find(
        (priceElement) => priceElement?.material === "SimpleCardboard"
      )?.price;
    const specialCardboardPrice =
      priceList.length > 0 &&
      priceList?.find(
        (priceElement) => priceElement?.material === "SpecialCardboard"
      )?.price;

    if (additionals.paperType === "simple") {
      dispatch(
        changeInvitePriceValue({
          key: "cardboard",
          value: simpleCardboardPrice,
        })
      );
    } else {
      dispatch(
        changeInvitePriceValue({
          key: "cardboard",
          value: specialCardboardPrice,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionals, priceList]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={{ xs: "center", md: "flex-start" }}
    >
      <Typography sx={{ fontWeight: "bold", padding: "5px" }}>
        Tipul de hartie
      </Typography>
      <Typography sx={{ padding: "5px" }}>
        Alege tipul de hartie care ti se potriveste
      </Typography>
      <Grid item container xs={8} gap={2}>
        <Grid
          item
          container
          xs={5}
          sm={3}
          onClick={() =>
            dispatch(
              changeAdditionalsValue({ key: "paperType", value: "simple" })
            )
          }
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            border:
              additionals?.paperType === "simple"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ fontSize: "13px" }}>Simpla</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          sm={3}
          onClick={() =>
            dispatch(
              changeAdditionalsValue({ key: "paperType", value: "bullet" })
            )
          }
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            border:
              additionals?.paperType === "bullet"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
              Texturata cu puncte
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={5}
          sm={3}
          onClick={() => {
            dispatch(
              changeAdditionalsValue({ key: "paperType", value: "line" })
            );
            if (inviteValues?.folioType !== "None") {
              dispatch(changeInviteValue({ key: "folioType", value: "None" }));
              dispatch(
                changeAdditionalsValue({ key: "folioType", value: "None" })
              );
            }
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            border:
              additionals?.paperType === "line"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          <Grid
            item
            container
            xs={8}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
              Texturata cu linii
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          item
          container
          xs={5}
          sm={2.5}
          onClick={() => {
            dispatch(
              changeAdditionalsValue({ key: "paperType", value: "black" })
            );
            if (inviteValues?.folioType === "None")
              dispatch(changeInviteValue({ key: "folioType", value: "Gold" }));
            dispatch(
              changeAdditionalsValue({ key: "folioType", value: "Gold" })
            );
          }}
          alignItems={"center"}
          style={{
            borderRadius: "4px",
            border:
              additionals?.paperType === "black"
                ? "1px solid black"
                : "1px solid lightgray",
          }}
        >
          {/* <Grid
            item
            container
            xs={8}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ fontSize: "12px" }}>Neagra</Typography>
          </Grid> */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};
